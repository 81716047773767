@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400&display=swap");
@media (max-width: 960px) {
	.custom-2020 #body {
		padding-top: 185px;
	}
}
.custom-2020 .page-container {
	width: 100%;
	display: block;
}
.custom-2020 .page-container h2 {
	text-transform: uppercase;
	font-size: 36px !important;
	font-weight: 400;
}
.custom-2020 .page-container p {
	font-size: 20px;
	line-height: 1.3;
	color: #58595b !important;
}
.custom-2020 .page-container p.page-title {
	color: #1d4486 !important;
	font-weight: 700;
}
.custom-2020 .page-container p.page-title.orange {
	color: #f58220 !important;
}
.custom-2020 .page-container p.page-title + p {
	margin-bottom: 30px;
	margin-top: -5px;
}
.custom-2020 .page-container .page-accent-button.scrunch {
	margin-top: -20px;
	margin-bottom: 30px;
	margin-left: 0;
}

.custom-2020 .page-container ul {
	font-size: 16px;
	line-height: 1.3;
	color: #58595b !important;
	margin: 0 0 10px;
	padding: 0;
	list-style: none;
}
.custom-2020 .page-container ul li {
	padding: 0 0 0 1em;
	position: relative;
}
.custom-2020 .page-container ul li:before {
	content: "";
	width: 5px;
	height: 5px;
	background-color: #f58220;
	border-radius: 50%;
	position: absolute;
	display: block;
	top: 0.5em;
	left: 0;
}
.custom-2020 .page-container ul li:not(:last-of-type) {
	margin-bottom: 10px;
}
.custom-2020 .page-container .page-accent-button,
.custom-2020 .page-container .page-large-button,
.custom-2020 .page-container .page-small-button {
	text-transform: uppercase;
	color: #fff;
	background: #1d4486;
	font-size: 20px;
	margin-top: 30px;
	text-align: center;
}
.custom-2020 .page-container .page-large-button {
	width: 100%;
	text-align: center;
	padding: 15px 20px;
	display: block;
	background-color: #2d68b2;
}
.custom-2020 .page-container .page-small-button {
	display: table;
	min-width: 220px;
	padding: 15px 40px;
	background-color: #2d68b2;
}
.custom-2020 .page-container .page-accent-button {
	padding: 7px 30px;
	border-radius: 5px;
	min-width: 210px;
	margin: 0 auto;
	display: table;
	margin-top: 30px;
}
.custom-2020 .page-container .page-header {
	margin: 20px 0 0;
	padding: 40px 0 0;
	border: none;
	position: relative;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header {
		padding-top: 62.5px;
	}
}
.custom-2020 .page-container .page-header .logo-badge {
	width: 200px;
	height: auto;
	display: block;
	position: absolute;
	top: 0;
	left: 5px;
	z-index: 4;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header .logo-badge {
		width: 125px;
		left: calc((100% - 125px) / 2);
	}
}
.custom-2020 .page-container .page-header h1 {
	color: #fff !important;
	text-transform: uppercase;
	margin: 0;
	padding: 10px 20px 10px 230px;
	max-width: calc(100% - 50px);
	width: auto;
	height: 70px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	font-size: 36px !important;
	font-weight: 400 !important;
	position: relative;
	background: #1d4685;
	background: linear-gradient(135deg, #1d4685, #2d68b2 90%, #2d68b2);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d4685",endColorstr="#2d68b2",GradientType=1);
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header h1 {
		height: auto;
		max-width: 100%;
		width: 100%;
		padding-left: 20px;
		padding-top: 67.5px;
		text-align: center;
		-ms-flex-pack: center;
		justify-content: center;
		font-size: 24px !important;
	}
}
.custom-2020 .page-container .page-header h1:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	right: -25px;
	top: 0;
	border-top: 35px solid transparent;
	border-bottom: 35px solid transparent;
	border-left: 25px solid #2d68b2;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header h1:after {
		display: none;
	}
}
.custom-2020 .page-container .page-header .page-nav {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 20px 10px 20px 230px;
	margin: 0;
	border-top: 1px solid #1d4486;
	border-bottom: 1px solid #1d4486;
	background: #fff;
	list-style: none;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header .page-nav {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 10px;
	}
}
.custom-2020 .page-container .page-header .page-nav li {
	padding: 0 20px;
	margin: 0 !important;
}
.custom-2020 .page-container .page-header .page-nav li:before {
	display: none;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header .page-nav li {
		padding: 10px;
	}
}
.custom-2020 .page-container .page-header .page-nav li:not(:last-of-type) {
	border-right: 1px solid #1d4486;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header .page-nav li:not(:last-of-type) {
		border-right: none;
		border-bottom: 1px solid #1d4486;
	}
}
.custom-2020 .page-container .page-header .page-nav li a {
	text-transform: uppercase;
	font-size: 20px;
	color: #1d4486;
	text-align: center;
}
.custom-2020 .page-container .page-header.ss-individual .page-logo {
	width: 100%;
	background: #fff;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 30px 0;
}
.custom-2020 .page-container .page-header.ss-individual .page-logo img {
	max-width: 350px;
	height: auto;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header.ss-individual .page-logo img {
		max-width: 225px;
	}
}
.custom-2020 .page-container .page-header.ss-individual .page-nav {
	padding: 0 10px;
	-ms-flex-pack: center;
	justify-content: center;
	border: none;
	-ms-flex-align: stretch;
	align-items: stretch;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header.ss-individual .page-nav {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.custom-2020 .page-container .page-header.ss-individual .page-nav li {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 0;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .page-header.ss-individual .page-nav li {
		width: 100%;
	}
}
.custom-2020 .page-container .page-header.ss-individual .page-nav li a {
	font-size: 16px;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
}
.custom-2020 .page-container .page-section {
	background: #fff;
	padding: 50px 0;
	width: 100%;
	display: block;
}
.custom-2020 .page-container .page-section.left .page-section-container {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}
@media (max-width: 768px) {
	.custom-2020 .page-container .page-section.left .page-section-container {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
}
.custom-2020 .page-container .page-section.left .page-section-container .page-inner {
	width: 100%;
	padding: 0 30px 0 0 !important;
}
@media (max-width: 768px) {
	.custom-2020 .page-container .page-section.left .page-section-container .page-inner {
		padding: 0;
	}
}
.custom-2020 .page-container .page-section.video .page-section-container,
.custom-2020 .page-container .page-section:nth-of-type(odd) .page-section-container {
	-ms-flex-direction: row;
	flex-direction: row;
}
@media (max-width: 768px) {
	.custom-2020 .page-container .page-section.video .page-section-container,
	.custom-2020 .page-container .page-section:nth-of-type(odd) .page-section-container {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.custom-2020 .page-container .page-section.video .page-section-container .page-inner,
.custom-2020 .page-container .page-section:nth-of-type(odd) .page-section-container .page-inner {
	width: 100%;
	padding: 0 0 0 30px;
}
@media (max-width: 768px) {
	.custom-2020 .page-container .page-section.video .page-section-container .page-inner,
	.custom-2020 .page-container .page-section:nth-of-type(odd) .page-section-container .page-inner {
		padding: 0;
	}
}
.page-container.npz .page-section .page-section-container {
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
@media (max-width: 768px) {
	.page-container.npz .page-section .page-section-container {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.page-container.npz .page-section .page-section-container .page-inner {
	width: 100%;
	padding: 0 30px 0 0;
}
@media (max-width: 768px) {
	.page-container.npz .page-section .page-section-container .page-inner {
		padding: 0;
	}
}
.page-container.npz .page-section .page-section-container:nth-of-type(odd) {
	-ms-flex-direction: row;
	flex-direction: row;
}
@media (max-width: 768px) {
	.page-container.npz .page-section .page-section-container:nth-of-type(odd) {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.page-container.npz .page-section .page-section-container:nth-of-type(odd) .page-inner {
	width: 100%;
	padding: 0 0 0 30px;
}
@media (max-width: 768px) {
	.page-container.npz .page-section .page-section-container:nth-of-type(odd) .page-inner {
		padding: 0;
	}
}
.custom-2020 .page-container .page-section .page-section-container {
	width: 90%;
	margin: 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
@media (max-width: 768px) {
	.custom-2020 .page-container .page-section .page-section-container {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.custom-2020 .page-container .page-section .page-section-container .page-img-container {
	width: 100%;
	display: block;
	position: relative;
}
.custom-2020 .page-container .page-section .page-section-container .page-img-container .logo {
	max-width: 350px;
	height: auto;
}
.custom-2020 .page-container .page-section .page-section-container .page-img-container.fancybox-media:after {
	content: "";
	width: 75px;
	height: 75px;
	background-color: #2d68b2;
	border-radius: 50%;
	position: absolute;
	top: calc((100% - 75px) / 2);
	left: calc((100% - 75px) / 2);
	z-index: 3;
}
.custom-2020 .page-container .page-section .page-section-container .page-img-container.fancybox-media:before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	top: calc(((100% - 75px) / 2) + 23px);
	left: calc(((100% - 75px) / 2) + 27px);
	z-index: 4;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 30px solid #fff;
}
.custom-2020 .page-container .page-section .page-section-container .page-img-container img {
	z-index: 1;
	width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
}
@media (max-width: 768px) {
	.custom-2020 .page-container .page-section .page-section-container .page-img-container img {
		max-width: 300px;
		margin: 30px auto;
	}
}
.custom-2020 .page-container .page-section .page-section-container .page-inner {
	width: 100%;
	padding: 0 30px 0 0;
}
@media (max-width: 768px) {
	.custom-2020 .page-container .page-section .page-section-container .page-inner {
		padding: 0;
	}
}
.custom-2020 .page-container .page-bottom-links {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-bottom: 60px;
}
.custom-2020 .page-container .page-bottom-links .page-small-button {
	min-width: 220px;
	padding: 15px 10px;
	margin-top: 15px;
	text-transform: none;
	font-size: 24px;
}
.custom-2020 .page-container .video-title {
	-ms-flex-pack: start;
	justify-content: flex-start;
}
.custom-2020 .page-container .video-title h2 {
	width: 100%;
}
.custom-2020 .page-container .two-col-video {
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.custom-2020 .page-container .two-col-video .slide {
	width: calc((100% - 50px) / 2);
}
@media (max-width: 960px) {
	.custom-2020 .page-container .two-col-video .slide {
		width: 100%;
	}
	.custom-2020 .page-container .two-col-video .slide:not(:last-of-type) {
		margin-bottom: 30px;
	}
}
.custom-2020 .page-container .video-slider-section .page-section-container {
	-ms-flex-direction: column;
	flex-direction: column;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .video-slider-section .page-section-container {
		width: calc(100% - 20px);
	}
}
.custom-2020 .page-container .video-slider-section .page-section-container h2 {
	width: 100%;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .video-slider-section .page-section-container h2 {
		width: 90%;
	}
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper {
	padding: 0 25px;
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-viewport {
	box-shadow: none;
	border: none;
	left: 0;
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-viewport .slide p {
	margin-top: 5px;
	color: #1d4486 !important;
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-pager {
	display: none;
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-prev {
	left: -20px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-prev {
		left: 0;
	}
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-next {
	right: -20px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-next {
		right: 0;
	}
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-next,
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-prev {
	background: none;
	width: 25px;
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-next:before,
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-prev:before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	border-top: 2px solid #1d4486;
	border-right: 2px solid #1d4486;
	position: absolute;
	top: 5px;
	left: 0;
	transform: rotate(45deg);
}
.custom-2020 .page-container .video-slider-section .page-section-container .bx-wrapper .bx-controls .bx-prev:before {
	transform: rotate(-135deg);
	left: 5px;
}
.custom-2020 .page-container .special-section {
	padding: 0;
}
.custom-2020 .page-container .special-section:nth-of-type(2) .page-section-container .page-inner .logo {
	margin-top: 70px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .special-section:nth-of-type(2) .page-section-container .page-inner .logo {
		margin-top: 0;
	}
}
.custom-2020 .page-container .special-section .page-section-container {
	width: 100%;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .special-section .page-section-container {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
}
.custom-2020 .page-container .special-section .page-section-container .page-img-container {
	width: 50% !important;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .special-section .page-section-container .page-img-container {
		width: 100% !important;
		min-height: 300px;
	}
}
.custom-2020 .page-container .special-section .page-section-container .page-img-container img {
	width: 90%;
	margin: 0 auto;
}
.custom-2020 .page-container .special-section .page-section-container .page-img-container.bg-img {
	background-size: cover;
	background-position: 50%;
}
.custom-2020 .page-container .special-section .page-section-container .page-inner {
	background: #e7e7e8;
	padding: 30px !important;
	width: 50% !important;
}
.custom-2020 .page-container .special-section .page-section-container .page-inner.full {
	width: 100% !important;
}
@media (max-width: 960px) {
	.custom-2020 .page-container .special-section .page-section-container .page-inner {
		width: 100% !important;
	}
}
.custom-2020 .page-container .special-section .page-section-container .page-inner .logo {
	width: 250px;
	height: auto;
	margin: 0 auto;
	display: block;
}
.custom-2020 .page-container .page-footer {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	border-top: 2px solid #1d4486;
	padding: 30px;
}
.custom-2020 .page-container .page-footer p {
	font-size: 20px;
	line-height: 1.3;
	text-align: center;
	margin-bottom: 30px;
	color: #1d4486 !important;
	font-weight: 700;
	max-width: 600px;
}
.custom-2020 .page-container .page-footer a {
	background-color: #f58220;
	color: #fff;
	text-align: center;
	padding: 15px 40px;
	min-width: 250px;
	font-size: 20px;
	text-transform: uppercase;
}
.custom-2020 #asides,
.custom-2020 #body header {
	display: none;
}
.custom-2020 #main .container-wrapper {
	background: none;
}
.custom-2020 #body {
	width: 100%;
	background: none;
}
@media (max-width: 960px) {
	.custom-2020 #body {
		padding-top: 155px;
	}
}
.custom-2020 .page-container.sponsor-page .page-header {
	margin: 0;
	padding: 0;
}
.custom-2020 .page-container.sponsor-page .page-header .page-logo {
	background: #fff;
	padding: 20px 0;
}
.custom-2020 .page-container.sponsor-page .page-header .page-logo img {
	width: 250px;
	height: auto;
	margin: 0 auto;
	display: block;
}
.custom-2020 .page-container.sponsor-page .page-image-hero {
	padding: 150px 30px;
	background-size: cover;
	background-position: 50%;
}
.custom-2020 .page-container.sponsor-page .page-image-hero h1 {
	text-align: center;
	font-size: 48px !important;
	margin: 0;
}
.custom-2020 .page-container.sponsor-page .page-subheader {
	background: #1d4486;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	padding: 15px 200px;
}
@media (max-width: 1024px) {
	.custom-2020 .page-container.sponsor-page .page-subheader {
		padding: 15px;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}
@media (max-width: 960px) {
	.custom-2020 .page-container.sponsor-page .page-subheader {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.custom-2020 .page-container.sponsor-page .page-subheader h2 {
		margin-bottom: 10px !important;
		text-align: center;
	}
}
.custom-2020 .page-container.sponsor-page .page-subheader h2 {
	font-size: 36px !important;
	text-transform: uppercase;
	color: #fff !important;
	margin: 0;
}
.custom-2020 .page-container.sponsor-page .page-subheader .sponsor-btn {
	position: absolute;
	right: 30px;
	top: calc((100% - 45px) / 2);
	display: -ms-flexbox;
	display: flex;
	height: 45px;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 1024px) {
	.custom-2020 .page-container.sponsor-page .page-subheader .sponsor-btn {
		position: relative;
		right: auto;
		top: auto;
	}
}
.custom-2020 .page-container.sponsor-page .page-single-column {
	background: #fff;
	padding: 50px 30px;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}
.custom-2020 .page-container.sponsor-page .page-single-column .page-col {
	width: 100%;
}
.custom-2020 .page-container.sponsor-page .page-single-column .page-col .responsive-video {
	max-width: 80%;
	width: 100%;
	margin: 0 auto;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.sponsor-page .page-single-column .page-col .responsive-video {
		max-width: 100%;
	}
}
.custom-2020 .page-container.sponsor-page .page-two-column {
	background: #fff;
	padding: 50px 30px;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.sponsor-page .page-two-column {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.custom-2020 .page-container.sponsor-page .page-two-column.no-padding {
	padding: 50px 0;
}
.custom-2020 .page-container.sponsor-page .page-two-column .page-col {
	width: 100%;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.sponsor-page .page-two-column .page-col:first-of-type {
		margin-bottom: 30px;
	}
}
.custom-2020 .page-container.sponsor-page .page-two-column .page-col.stretch-img {
	min-height: 400px;
	background-size: cover;
	background-position: 50%;
}
.custom-2020 .page-container.sponsor-page .page-two-column.right-col .page-col:nth-of-type(2) {
	padding-left: 30px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.sponsor-page .page-two-column.right-col .page-col:nth-of-type(2) {
		padding: 0;
	}
}
.custom-2020 .page-container.sponsor-page .page-two-column.left-col .page-col:first-of-type {
	padding-right: 30px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.sponsor-page .page-two-column.left-col .page-col:first-of-type {
		padding: 0;
	}
}
.custom-2020 .page-container.sponsor-page .page-link-container {
	background: #fff;
	padding: 10px 50px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.sponsor-page .page-link-container {
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.custom-2020 .page-container.sponsor-page .page-link-container a {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 500;
}
.custom-2020 .page-container.sponsor-page .page-img-container {
	background: #fff;
	padding: 50px 10px;
}
.custom-2020 .page-container.sponsor-page .page-img-container.no-btm {
	padding-bottom: 0 !important;
}
.custom-2020 .page-container.sponsor-page .page-img-container img {
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	display: block;
}
.custom-2020 .page-container.sponsor-page .sponsor-btn {
	background: #fff;
	color: #1d4486;
	font-size: 24px;
	padding: 10px 20px;
	border: 1px solid #1d4486;
	font-weight: 700;
}
.custom-2020 .page-footer.two-col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: space-between;
	align-items: space-between;
}
@media (max-width: 960px) {
	.custom-2020 .page-footer.two-col {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.custom-2020 .page-footer.two-col p {
	max-width: 100%;
	width: 100%;
	text-align: left;
	margin: 0;
	right: 20px;
}
@media (max-width: 960px) {
	.custom-2020 .page-footer.two-col p {
		margin-bottom: 20px;
	}
}
.custom-2020 .video-preview,
.custom-2020 .video-preview .cover {
	width: 100%;
	height: auto;
	position: relative;
}
.custom-2020 .video-preview .cover {
	z-index: 4;
	opacity: 1;
	transition: all 1s;
	cursor: pointer;
}
.custom-2020 .video-preview.open .cover {
	opacity: 0;
	pointer-events: none;
}
.custom-2020 .video-preview .responsive-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}
#leviton-2021 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
#leviton-2021 p.title {
	font-size: 36px !important;
	color: #1d4486 !important;
}
#leviton-2021 ul li:before {
	border-radius: 0;
	background: #7dc129;
}
#leviton-2021 .sponsor-btn:after {
	background-image: url(/assets/images/2021/jan/imgs/leviton-arrow.png);
	width: 18px;
	height: 20px;
	content: "";
	display: block;
	margin-left: 10px;
}
#leviton-2021 .page-image-hero {
	padding: 150px 90px;
}
@media (max-width: 960px) {
	#leviton-2021 .page-image-hero {
		padding: 75px 10px;
	}
}
#leviton-2021 .page-image-hero h1 {
	color: #fff;
	line-height: 1.2;
}
#leviton-2021 .page-subheader {
	background: #7dc129;
}
#leviton-2021 .page-subheader.spacing-top {
	border-top: 50px solid #fff;
}
#leviton-2021 .page-subheader h2 {
	font-weight: 100;
}
#leviton-2021 .page-two-column {
	padding-bottom: 0;
}
#leviton-2021 .page-img-container {
	padding: 50px 10px;
}
#leviton-2021 .page-footer {
	border-top: none;
	background-color: #1d4486;
}
#leviton-2021 .page-footer p {
	color: #fff !important;
}
#leviton-2021 .page-footer a.sponsor-btn {
	display: -ms-flexbox;
	display: flex;
	text-transform: none;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 0;
}
#squared-2021 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
#squared-2021 h1 {
	color: #3dcd58;
	font-size: 48px;
	font-weight: 400;
}
#squared-2021 p.title {
	font-size: 36px !important;
	color: #1d4486 !important;
}
#squared-2021 ul li:before {
	border-radius: 50%;
	background: #000;
}
#squared-2021 .sponsor-btn {
	background-color: #0093d0;
	color: #fff;
	font-weight: 400;
	border: none;
}
#squared-2021 .page-subheader {
	background: #3dcd58;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 15px 200px 15px 15px;
}
@media (max-width: 1024px) {
	#squared-2021 .page-subheader {
		padding: 15px;
	}
}
@media (max-width: 960px) {
	#squared-2021 .page-subheader {
		padding: 15px;
	}
}
#squared-2021 .page-subheader img {
	margin: 0;
}
@media (max-width: 960px) {
	#squared-2021 .page-subheader img {
		margin-bottom: 20px;
	}
}
#squared-2021 .page-image-hero {
	padding: 0;
}
#squared-2021 .page-image-hero img,
#squared-2021 .page-two-column .page-col img {
	width: 100%;
	height: auto;
}
#squared-2021 .section-01 {
	padding-top: 20px;
}
#squared-2021 .section-02 {
	padding: 0 30px;
}
#squared-2021 .section-02 .page-col:first-of-type {
	width: 40%;
}
@media (max-width: 960px) {
	#squared-2021 .section-02 .page-col:first-of-type {
		width: 100%;
	}
}
#squared-2021 .section-02 .page-col:first-of-type img {
	width: 80%;
	margin: 0 auto;
	display: block;
}
@media (max-width: 960px) {
	#squared-2021 .section-02 .page-col:first-of-type img {
		width: 100%;
		max-width: 350px;
	}
}
#squared-2021 .section-02 .page-col:nth-of-type(2) {
	width: 60%;
	padding-left: 20px;
}
@media (max-width: 960px) {
	#squared-2021 .section-02 .page-col:nth-of-type(2) {
		width: 100%;
		padding-left: 0;
	}
}
#squared-2021 .section-04,
#squared-2021 .section-05 {
	background-color: #3dcd58;
	color: #fff;
	padding: 0 30px;
}
#squared-2021 .section-04 p,
#squared-2021 .section-04 ul li,
#squared-2021 .section-05 p,
#squared-2021 .section-05 ul li {
	color: #fff !important;
}
#squared-2021 .section-04 p,
#squared-2021 .section-05 p {
	font-size: 24px;
}
#squared-2021 .section-04 ul,
#squared-2021 .section-05 ul {
	margin-bottom: 30px;
}
#squared-2021 .section-04 ul li:before,
#squared-2021 .section-05 ul li:before {
	background-color: #fff;
}
#squared-2021 .section-04 {
	padding-top: 30px;
	padding-bottom: 20px;
}
#squared-2021 .section-04 .page-col:first-of-type {
	width: 65%;
}
@media (max-width: 960px) {
	#squared-2021 .section-04 .page-col:first-of-type {
		width: 100%;
	}
}
#squared-2021 .section-04 .page-col:first-of-type img {
	width: 80%;
}
@media (max-width: 960px) {
	#squared-2021 .section-04 .page-col:first-of-type img {
		max-width: 350px;
		margin: 0 auto;
		display: block;
	}
}
#squared-2021 .section-04 .page-col:nth-of-type(2) {
	width: 35%;
}
@media (max-width: 960px) {
	#squared-2021 .section-04 .page-col:nth-of-type(2) {
		width: 100%;
	}
	#squared-2021 .section-04 .page-col:nth-of-type(2) img {
		max-width: 350px;
		margin: 0 auto;
		display: block;
	}
}
#squared-2021 .section-05 {
	padding-bottom: 30px;
}
@media (max-width: 960px) {
	#squared-2021 .section-05 .page-col:first-of-type {
		margin-bottom: 0;
	}
}
#squared-2021 .page-footer {
	border: none;
	background-color: #ccc;
}
#squared-2021 .page-footer p {
	color: #000 !important;
	font-weight: 400;
	padding-right: 30px;
}
#lutron-2021 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
#lutron-2021 .page-image-hero {
	height: 500px;
	background-size: cover;
	background-position: 50%;
	position: relative;
	z-index: 1;
}
#lutron-2021 .page-image-hero .inner {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 4;
	background: #315051;
	background: linear-gradient(135deg, #315051, rgba(49, 80, 81, 0));
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#315051",endColorstr="#315051",GradientType=1);
	width: 100%;
	padding: 25px 30px;
}
#lutron-2021 .page-image-hero .inner h1 {
	color: #fff;
	font-size: 48px;
	font-weight: 400;
	font-weight: 100 !important;
	margin: 0;
	text-align: left;
}
#lutron-2021 .page-image-hero .inner p {
	text-align: left;
	margin: 0;
	color: #fff !important;
	font-weight: 700;
}
#lutron-2021 p.title {
	color: #c0571c !important;
	font-weight: 700;
}
#lutron-2021 ul li {
	color: #344948 !important;
}
#lutron-2021 ul li:before {
	background: #344948 !important;
}
#lutron-2021 .sponsor-btn {
	background: #c0571c;
	border-radius: 20px;
	color: #fff;
	font-weight: 400;
	margin: 20px auto;
	font-size: 20px;
	display: table;
	border: none;
	text-transform: normal !important;
}
#lutron-2021 .page-subheader {
	background: #fff !important;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 30px 30px 0;
	margin-bottom: -20px;
}
#lutron-2021 .page-subheader h2 {
	font-size: 48px !important;
	color: #344948 !important;
	text-transform: none !important;
	font-weight: 100;
	margin-bottom: 0;
}
#lutron-2021 .page-subheader h2 span {
	font-size: 0.65em;
	font-weight: 700 !important;
}
#lutron-2021 .page-image-hero,
#lutron-2021 .page-img-container {
	padding: 0;
}
#lutron-2021 .border-top {
	border-top: 1px solid #344948;
	padding-top: 60px;
}
#lutron-2021 .page-footer,
#lutron-2021 .page-logo {
	background-size: cover;
	background-position: 50%;
}
#lutron-2021 .page-footer p {
	color: #fff !important;
	font-weight: 400;
	max-width: 100%;
	margin: 0 0 20px;
}
#lutron-2021 .page-footer .sponsor-btn {
	background: none;
	border: 2px solid #c0571c !important;
	border-radius: 20px;
	color: #c0571c;
	font-weight: 400;
	border: none;
	text-transform: normal !important;
}
#acuity-2021 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
#acuity-2021 h1 {
	color: #0093d0;
	font-size: 48px;
	font-weight: 400;
}
#acuity-2021 p.title {
	font-size: 36px !important;
	color: #0093d0 !important;
	margin-bottom: 20px;
}
#acuity-2021 ul li:before {
	border-radius: 50%;
	background: #000;
}
#acuity-2021 .sponsor-btn {
	background-color: #0093d0 !important;
	color: #fff;
	font-weight: 400;
	border: none;
	font-weight: 700;
	border-left: 2px solid #fff;
}
@media (max-width: 960px) {
	#acuity-2021 .sponsor-btn {
		border-left: none;
	}
}
#acuity-2021 .page-logo img {
	margin-left: 30px;
}
#acuity-2021 .topbar {
	padding: 15px 200px 15px 130px !important;
}
@media (max-width: 1024px) {
	#acuity-2021 .topbar {
		padding: 15px 15px 15px 130px !important;
	}
}
@media (max-width: 960px) {
	#acuity-2021 .topbar {
		padding: 15px 15px 15px 100px !important;
	}
}
#acuity-2021 .topbar img {
	position: absolute;
	top: -10px;
	left: 30px;
}
@media (max-width: 960px) {
	#acuity-2021 .topbar img {
		left: 0;
	}
}
#acuity-2021 .page-image-hero {
	background: #ccc;
	padding: 15px 30px;
	border-bottom: 20px solid #fff;
}
#acuity-2021 .page-image-hero h1 {
	color: #0e3268;
	font-family: Roboto Slab, serif;
}
#acuity-2021 .page-subheader {
	background: #0093d0;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 15px 200px 15px 15px;
}
@media (max-width: 1024px) {
	#acuity-2021 .page-subheader {
		padding: 15px;
	}
}
@media (max-width: 960px) {
	#acuity-2021 .page-subheader {
		padding: 15px;
	}
}
#acuity-2021 .page-subheader h2 {
	font-family: Roboto Slab, serif;
}
#acuity-2021 .page-subheader img {
	margin: 0;
}
@media (max-width: 960px) {
	#acuity-2021 .page-subheader img {
		margin-bottom: 20px;
	}
}
#acuity-2021 .second {
	border-top: 60px solid #fff;
}
#acuity-2021 .page-two-column {
	padding: 0;
	background: #f2f2f2;
}
#acuity-2021 .page-two-column .page-col:nth-of-type(2) {
	padding: 50px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
#acuity-2021 .page-two-column .stretch-img {
	min-height: 300px;
}
#acuity-2021 .page-two-column ul li {
	color: #666;
}
#acuity-2021 .page-two-column ul li:before {
	background: #666;
}
#acuity-2021 .page-footer {
	background: #ccc;
	border-top: 0;
}
#acuity-2021 .page-footer p {
	color: #666 !important;
	padding-right: 50px;
}
#acuity-2021 .page-footer .sponsor-btn {
	border-left: none;
}
#nvent-2021 p.title {
	font-size: 24px;
}
#nvent-2021 h2 {
	font-size: 28px !important;
	margin: 0 0 25px;
}
#nvent-2021 p,
#nvent-2021 ul li {
	font-size: 18px;
}
#nvent-2021 .page-subheader {
	background: #fff;
	padding: 20px;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
#nvent-2021 .page-subheader .logo {
	width: 150px;
}
@media (max-width: 960px) {
	#nvent-2021 .page-subheader .logo {
		margin-bottom: 20px;
	}
}
#nvent-2021 .page-subheader .sponsor-btn {
	color: #fff;
	background: #b31b28;
	border-radius: 30px;
	border: none;
	text-transform: uppercase;
	font-size: 18px;
	position: relative;
}
#nvent-2021 .page-image-hero {
	padding-bottom: 30px;
	min-height: 300px;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-align: end;
	align-items: flex-end;
	display: -ms-flexbox;
	display: flex;
}
#nvent-2021 .page-image-hero h1 {
	color: #fff;
	text-align: left;
}
#nvent-2021 .page-two-column {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
#nvent-2021 .page-two-column h2 {
	width: 100%;
	color: #000;
}
#nvent-2021 .page-two-column .page-col {
	width: 50%;
}
#nvent-2021 .section-01 h2 {
	text-transform: none;
}
#nvent-2021 .section-01 .page-col:first-of-type {
	width: 65%;
	padding-right: 30px;
}
@media (max-width: 960px) {
	#nvent-2021 .section-01 .page-col:first-of-type {
		width: 100%;
		padding-right: 0;
	}
}
#nvent-2021 .section-01 .page-col:nth-of-type(2) {
	width: 35%;
}
@media (max-width: 960px) {
	#nvent-2021 .section-01 .page-col:nth-of-type(2) {
		width: 100%;
		max-width: 300px;
		margin: 0 auto;
	}
}
#nvent-2021 .section-01 .page-col:nth-of-type(2) img {
	width: 100%;
	height: auto;
}
#nvent-2021 .section-02 {
	border-top: 1px solid #000;
	padding-bottom: 0;
}
#nvent-2021 .section-02 .page-col {
	width: calc((100% - 40px) / 2);
	margin-bottom: 20px;
	border: 1px solid #000;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
}
@media (max-width: 960px) {
	#nvent-2021 .section-02 .page-col {
		width: 100%;
	}
}
#nvent-2021 .section-02 .page-col:nth-of-type(odd) {
	margin-right: 20px;
}
@media (max-width: 960px) {
	#nvent-2021 .section-02 .page-col:nth-of-type(odd) {
		margin-right: 0;
	}
}
#nvent-2021 .section-02 .page-col .icon {
	width: 90px;
	background-color: #ffeb56;
	-ms-flex-align: center;
	align-items: center;
}
#nvent-2021 .section-02 .page-col .icon,
#nvent-2021 .section-02 .page-col .inner {
	padding: 15px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
}
#nvent-2021 .section-02 .page-col .inner {
	-ms-flex-direction: column;
	flex-direction: column;
}
#nvent-2021 .section-02 .page-col .inner p {
	margin: 0;
}
#nvent-2021 .section-03 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
#nvent-2021 .section-03 .page-col:first-of-type {
	width: 60%;
}
@media (max-width: 960px) {
	#nvent-2021 .section-03 .page-col:first-of-type {
		width: 100%;
	}
}
#nvent-2021 .section-03 .page-col:first-of-type img {
	width: 100%;
	height: auto;
}
#nvent-2021 .section-03 .page-col:nth-of-type(2) {
	width: 40%;
	padding-left: 30px;
}
@media (max-width: 960px) {
	#nvent-2021 .section-03 .page-col:nth-of-type(2) {
		width: 100%;
		padding-left: 0;
	}
}
#nvent-2021 .section-04 {
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 1px solid #000;
}
#nvent-2021 .section-04 .page-col {
	width: calc((100% - 100px) / 2);
	border: 1px solid #000;
	padding: 30px;
}
@media (max-width: 960px) {
	#nvent-2021 .section-04 .page-col {
		width: 100%;
	}
}
#nvent-2021 .section-04 .page-col:first-of-type {
	margin-right: 20px;
}
@media (max-width: 960px) {
	#nvent-2021 .section-04 .page-col:first-of-type {
		margin-right: 0;
	}
}
#nvent-2021 .section-04 .page-col ul li:before {
	background-color: #b31b28;
}
#nvent-2021 .section-04 .page-col img {
	width: 100%;
	height: auto;
}
#nvent-2021 .page-footer {
	background: #b31b28;
	color: #fff !important;
	border-top: none;
}
#nvent-2021 .page-footer p {
	color: #fff !important;
}
#nvent-2021 .page-footer a {
	color: #fff;
	border-color: #fff;
	border-radius: 30px;
	background: #b31b28;
}
#abb-2021 .section-01 {
	padding: 0;
}
@media (max-width: 960px) {
	#abb-2021 .section-01 {
		width: 100%;
	}
}
#abb-2021 .section-01 .page-col:first-of-type {
	width: 35%;
	padding: 20px;
}
@media (max-width: 960px) {
	#abb-2021 .section-01 .page-col:first-of-type {
		width: 100%;
	}
}
#abb-2021 .section-01 .page-col:first-of-type img {
	width: 170px;
}
#abb-2021 .section-01 .page-col:first-of-type h1 {
	font-size: 36px;
	color: #000;
	font-weight: 100;
	margin-bottom: 30px;
}
#abb-2021 .section-01 .page-col:first-of-type .sponsor-btn {
	margin-top: 30px;
	display: table;
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	background-color: #6e6e6e;
	border: none;
	padding: 15px;
}
#abb-2021 .section-01 .page-col:nth-of-type(2) {
	width: 65%;
}
@media (max-width: 960px) {
	#abb-2021 .section-01 .page-col:nth-of-type(2) {
		width: 100%;
	}
}
#abb-2021 .section-01 .page-col:nth-of-type(2) img {
	width: 100%;
	height: auto;
}
#abb-2021 .section-02 {
	background-size: cover;
	background-position: 50%;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-bottom: 60px solid #fff;
}
#abb-2021 .section-02 h2 {
	color: #fff;
	text-transform: none;
	width: 100%;
	margin: 0 0 20px;
	position: relative;
}
#abb-2021 .section-02 h2:before {
	content: "";
	width: 40px;
	height: 3px;
	display: block;
	background: #fff;
	position: absolute;
	top: -10px;
	left: 0;
}
#abb-2021 .section-02 .page-col {
	width: 50%;
}
@media (max-width: 960px) {
	#abb-2021 .section-02 .page-col {
		width: 100%;
	}
}
#abb-2021 .section-02 .page-col:nth-of-type(2) {
	padding-left: 30px;
}
@media (max-width: 960px) {
	#abb-2021 .section-02 .page-col:nth-of-type(2) {
		padding-left: 0;
	}
}
#abb-2021 .section-02 .page-col:nth-of-type(2) p {
	color: #fff !important;
}
#abb-2021 h2.step-title {
	width: 100%;
	text-transform: none;
	background-color: #ededed;
	color: #000;
	font-size: 24px !important;
	padding: 10px 30px;
	margin: 0;
}
#abb-2021 .section-03 {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: 40px;
}
#abb-2021 .section-03 .page-col {
	width: calc((100% - 200px) / 2);
	margin-bottom: 40px;
}
@media (max-width: 960px) {
	#abb-2021 .section-03 .page-col {
		width: 100%;
	}
}
#abb-2021 .section-03 .page-col:nth-of-type(odd) {
	margin-right: 40px;
}
@media (max-width: 960px) {
	#abb-2021 .section-03 .page-col:nth-of-type(odd) {
		margin-right: 0;
	}
}
#abb-2021 .section-03 .page-col img {
	width: 100%;
	margin-bottom: 20px;
}
#abb-2021 .page-footer {
	background-color: #6e6e6e;
	border-top: 0;
}
#abb-2021 .page-footer p {
	color: #fff !important;
}
#abb-2021 .page-footer .sponsor-btn {
	background-color: #fb0006;
	color: #fff !important;
	text-transform: none;
	font-weight: 400;
}
#ledvance-2021 p.title {
	font-size: 24px;
}
#ledvance-2021 h2 {
	font-size: 28px !important;
	margin: 0 0 25px;
}
#ledvance-2021 p,
#ledvance-2021 ul li {
	font-size: 18px;
}
#ledvance-2021 ul li:before {
	content: "";
	width: 0;
	height: 0;
	top: 0.4em;
	border-radius: 0;
	background: none;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #f60;
}
#ledvance-2021 .full-hero {
	width: 100%;
	height: auto;
}
#ledvance-2021 .page-subheader {
	background: #f60;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: stretch;
	align-items: stretch;
	padding: 0;
}
@media (max-width: 960px) {
	#ledvance-2021 .page-subheader {
		display: block;
	}
}
#ledvance-2021 .page-subheader .logo {
	width: 250px;
	margin: 10px 30px;
}
@media (max-width: 960px) {
	#ledvance-2021 .page-subheader .logo {
		margin-bottom: 20px;
	}
}
#ledvance-2021 .page-subheader .sponsor-btn {
	color: #fff;
	background: #666;
	border: none;
	font-size: 18px;
	padding: 43px;
	display: -ms-flexbox;
	display: flex;
	right: auto;
	position: relative;
}
#ledvance-2021 .page-subheader .sponsor-btn:after {
	content: "";
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-left: 7px solid #fff;
	margin-left: 10px;
}
#ledvance-2021 .section-01 {
	padding: 30px;
}
#ledvance-2021 .section-01 h1 {
	color: #f60;
	font-size: 42px;
	font-weight: 100;
}
#ledvance-2021 .section-03 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 30px;
}
#ledvance-2021 .section-03 .page-col:first-of-type {
	width: 50%;
}
@media (max-width: 960px) {
	#ledvance-2021 .section-03 .page-col:first-of-type {
		width: 100%;
	}
}
#ledvance-2021 .section-03 .page-col:first-of-type img {
	width: 100%;
	height: auto;
}
#ledvance-2021 .section-03 .page-col:nth-of-type(2) {
	width: 50%;
	padding-left: 30px;
	margin-left: 30px;
	border-left: 2px solid #f60;
}
@media (max-width: 960px) {
	#ledvance-2021 .section-03 .page-col:nth-of-type(2) {
		border-left: none;
		width: 100%;
		padding-left: 0;
		margin-left: 0;
	}
}
#ledvance-2021 .page-footer {
	background: #666;
	color: #fff !important;
	border-top: none;
}
#ledvance-2021 .page-footer p {
	color: #fff !important;
}
#ledvance-2021 .page-footer a {
	color: #fff;
	background: none;
	border: none;
}
#ledvance-2021 .page-footer a img {
	margin-right: 10px;
}
.custom-2020 .page-container.schneider-july .green {
	color: #3dcd58 !important;
}
.custom-2020 .page-container.schneider-july .blue {
	color: #00bdf2 !important;
}
.custom-2020 .page-container.schneider-july .page-subheader {
	background-color: #fff;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 10px;
}
.custom-2020 .page-container.schneider-july .page-subheader .sponsor-btn {
	position: relative;
	top: auto;
	right: auto;
	border: none;
	color: #000;
}
.custom-2020 .page-container.schneider-july .logo-bar {
	background-color: #3dcd58;
	padding: 5px 30px;
	display: -ms-flexbox;
	display: flex;
}
.custom-2020 .page-container.schneider-july .hero {
	position: relative;
}
.custom-2020 .page-container.schneider-july .hero img {
	width: 100%;
	height: auto;
	position: relative;
	z-index: 1;
}
.custom-2020 .page-container.schneider-july .hero .page-col {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 4;
	padding: 15px 30px;
	background-color: rgba(61, 205, 88, 0.7);
	width: 100%;
}
.custom-2020 .page-container.schneider-july .hero .page-col h1 {
	font-size: 42px !important;
	color: #fff !important;
	margin: 0 !important;
	font-weight: 400 !important;
}
@media (max-width: 768px) {
	.custom-2020 .page-container.schneider-july .hero .page-col h1 {
		font-size: 28px !important;
	}
}
.custom-2020 .page-container.schneider-july .hero-two-col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .hero-two-col {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.custom-2020 .page-container.schneider-july .hero-two-col .page-col {
	width: 50%;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .hero-two-col .page-col {
		width: 100%;
	}
}
.custom-2020 .page-container.schneider-july .hero-two-col .page-col:first-of-type {
	padding: 30px;
}
.custom-2020 .page-container.schneider-july .hero-two-col .page-col:first-of-type h1 {
	font-size: 48px !important;
	color: #000 !important;
	font-weight: 400 !important;
}
.custom-2020 .page-container.schneider-july .hero-two-col .page-col:first-of-type h1 span {
	text-transform: uppercase;
	color: #3dcd58 !important;
	width: 100%;
	display: block;
}
.custom-2020 .page-container.schneider-july .hero-two-col .page-col:first-of-type p {
	font-size: 20px;
}
.custom-2020 .page-container.schneider-july .hero-two-col .page-col:nth-of-type(2) img {
	width: 100%;
	height: auto;
}
.custom-2020 .page-container.schneider-july .intro-section .page-col:first-of-type {
	background: #3dcd58;
	padding: 10px 30px;
}
.custom-2020 .page-container.schneider-july .intro-section .page-col:first-of-type p {
	font-size: 28px;
	color: #fff !important;
	margin: 0 !important;
}
.custom-2020 .page-container.schneider-july .intro-section .page-col:nth-of-type(2) {
	background: #fff;
	border-bottom: 1px solid #3dcd58;
	padding: 15px 30px 30px;
}
.custom-2020 .page-container.schneider-july .intro-section .page-col:nth-of-type(2) p {
	font-size: 22px;
	color: #3dcd58 !important;
	margin: 0 !important;
}
.custom-2020 .page-container.schneider-july .section-01 {
	-ms-flex-align: center;
	align-items: center;
}
.custom-2020 .page-container.schneider-july .section-01 .page-col:first-of-type {
	padding-right: 30px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-01 .page-col:first-of-type {
		padding-right: 0;
	}
}
.custom-2020 .page-container.schneider-july .section-01 img {
	max-width: 100%;
	margin: 0 auto;
	display: block;
}
.custom-2020 .page-container.schneider-july .section-02 {
	padding-bottom: 0;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-02 {
		padding: 0;
	}
}
.custom-2020 .page-container.schneider-july .section-02 .page-col {
	max-width: 400px;
	margin: 0 auto;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-02 .page-col {
		margin-bottom: 75px !important;
	}
}
.custom-2020 .page-container.schneider-july .section-02 .page-col img {
	margin: 0 auto 10px;
	display: block;
}
.custom-2020 .page-container.schneider-july .section-03 img {
	width: 100%;
	height: auto;
}
.custom-2020 .page-container.schneider-july .section-03 .page-col:first-of-type {
	width: 200px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-03 .page-col:first-of-type {
		margin: 0 auto;
	}
}
.custom-2020 .page-container.schneider-july .section-03 .page-col:nth-of-type(2) {
	width: calc(100% - 200px);
	padding-left: 30px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-03 .page-col:nth-of-type(2) {
		width: 100%;
		padding-left: 0;
	}
}
.custom-2020 .page-container.schneider-july .section-04 {
	padding: 0 30px 0 0;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-04 {
		padding: 0;
	}
}
.custom-2020 .page-container.schneider-july .section-04 ul li:before {
	background-color: #3dcd58;
}
.custom-2020 .page-container.schneider-july .section-04 p,
.custom-2020 .page-container.schneider-july .section-04 ul li {
	font-size: 18px;
}
.custom-2020 .page-container.schneider-july .section-04 .page-col:first-of-type img {
	width: 100%;
	height: auto;
}
.custom-2020 .page-container.schneider-july .section-04 .page-col:nth-of-type(2) {
	padding-left: 30px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-04 .page-col:nth-of-type(2) {
		padding-left: 30px;
	}
}
.custom-2020 .page-container.schneider-july .section-05 {
	padding: 30px 30px 0;
	-ms-flex-align: center;
	align-items: center;
}
.custom-2020 .page-container.schneider-july .section-05 p {
	font-size: 18px;
}
.custom-2020 .page-container.schneider-july .section-05 .page-col:first-of-type {
	padding-right: 30px;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-05 .page-col:first-of-type {
		padding-right: 0;
	}
}
.custom-2020 .page-container.schneider-july .section-06 {
	padding: 30px;
}
.custom-2020 .page-container.schneider-july .section-06 .inner {
	width: 100%;
	margin: 0 30px;
	padding: 30px;
	border: 1px solid rgba(0, 0, 0, 0.5);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .section-06 .inner {
		-ms-flex-direction: column;
		flex-direction: column;
		width: calc(100% - 0px);
		margin: 0;
	}
}
.custom-2020 .page-container.schneider-july .section-06 .inner p.title {
	font-size: 32px;
}
.custom-2020 .page-container.schneider-july .section-06 .inner .page-col:nth-of-type(2) {
	padding-left: 30px;
}
.custom-2020 .page-container.schneider-july .section-06 .inner .page-col:nth-of-type(2) p.green span {
	color: #000 !important;
}
.custom-2020 .page-container.schneider-july .page-three-column {
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 30px;
	background-color: #fff;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .page-three-column {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.custom-2020 .page-container.schneider-july .page-three-column .page-col {
	width: 32%;
	border: 1px solid rgba(0, 0, 0, 0.5);
	-ms-flex: none;
	flex: none;
}
@media (max-width: 960px) {
	.custom-2020 .page-container.schneider-july .page-three-column .page-col {
		width: 100%;
		margin-bottom: 30px;
	}
}
.custom-2020 .page-container.schneider-july .page-three-column .page-col img {
	width: 100%;
	height: auto;
}
.custom-2020 .page-container.schneider-july .page-three-column .page-col p {
	padding: 10px;
}
.custom-2020 .page-container.schneider-july .page-three-column .page-col p.disclaimer {
	font-size: 0.9em;
}
.custom-2020 .page-container.schneider-july p.title {
	color: #3dcd58 !important;
	font-size: 24px;
}
.custom-2020 .page-container.schneider-july p.title.blue {
	color: #00bdf2 !important;
}
.custom-2020 .page-container.schneider-july .green-btn {
	display: table;
	margin: 20px auto 0;
	padding: 10px 40px;
	color: #fff;
	background: #3dcd58;
	border-radius: 10px;
	font-size: 18px;
}
.custom-2020 .page-container.schneider-july .page-footer {
	padding: 15px 0 0;
	border-top: 1px solid #3dcd58;
}
.custom-2020 .page-container.schneider-july .page-footer p {
	color: #000 !important;
	margin: 0 0 15px !important;
}
.custom-2020 .page-container.schneider-july .page-footer .green-rw {
	background: #3dcd58;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 20px;
}
.custom-2020 .page-container.schneider-july .page-footer .green-rw .sponsor-btn {
	color: #fff;
	background: #000;
	text-transform: none;
	border-radius: 10px;
	padding: 10px 20px;
	-ms-flex: none;
	flex: none;
	min-width: 0 !important;
	width: 170px !important;
}
.small-images.page-container .page-section .page-section-container .page-img-container img {
	width: 50%;
	margin: 0 auto;
	display: block;
}
.double-npz {
	padding-top: 0 !important;
}
.double-npz .page-section-container {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}
@media (max-width: 960px) {
	.double-npz .page-section-container {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
}
.double-npz .page-section-container .page-inner {
	padding: 0 30px 0 0 !important;
}
@media (max-width: 960px) {
	.double-npz .page-section-container .page-inner {
		padding: 0 !important;
	}
}
#eaton-2021 .blue {
	color: #185dac !important;
}
#eaton-2021 .page-subheader {
	background-color: #fff;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0 30px;
}
@media (max-width: 960px) {
	#eaton-2021 .page-subheader {
		padding: 0 30px 30px;
	}
}
#eaton-2021 .page-subheader .sponsor-btn {
	position: relative;
	top: auto;
	right: auto;
	border: none;
	text-transform: uppercase;
	color: #fff;
	background-color: #185dac;
	font-weight: 400;
	font-size: 16px;
	padding: 5px 20px;
	border-radius: 7px;
}
#eaton-2021 .page-subheader .sponsor-btn img {
	margin-left: 10px;
}
#eaton-2021 .hero {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	background-size: cover;
	background-position: bottom;
}
#eaton-2021 .hero h1 {
	color: #fff !important;
	font-size: 48px !important;
}
@media (max-width: 960px) {
	#eaton-2021 .hero h1 {
		font-size: 36px !important;
	}
}
#eaton-2021 .hero p {
	color: #fff !important;
	font-size: 36px !important;
	font-weight: 100 !important;
}
@media (max-width: 960px) {
	#eaton-2021 .hero p {
		font-size: 28px !important;
	}
}
#eaton-2021 .hero .page-col:first-of-type {
	width: calc(100% - 300px);
}
@media (max-width: 960px) {
	#eaton-2021 .hero .page-col:first-of-type {
		width: 100%;
	}
}
#eaton-2021 .hero .page-col:nth-of-type(2) {
	width: 300px;
}
@media (max-width: 960px) {
	#eaton-2021 .hero .page-col:nth-of-type(2) {
		width: 100%;
	}
}
#eaton-2021 .hero .page-col:nth-of-type(2) img {
	max-width: 100%;
	margin: 0 auto;
	display: block;
}
#eaton-2021 .section-02 {
	padding: 30px 50px 50px;
}
@media (max-width: 960px) {
	#eaton-2021 .section-02 {
		padding: 30px;
	}
}
#eaton-2021 .section-02 h2 {
	text-transform: inherit;
	color: #8b9398 !important;
	margin-bottom: 0;
}
#eaton-2021 .section-02 h2 span {
	color: #185dac !important;
}
#eaton-2021 .section-02 p strong {
	color: #000;
}
#eaton-2021 .section-02 p {
	color: #8b9398 !important;
}
#eaton-2021 .section-02 p.blue {
	color: #185dac !important;
	margin-top: 30px;
	font-weight: Bold;
	margin-bottom: 5px;
}
#eaton-2021 .section-03 {
	background: #bbd0e7;
	background: linear-gradient(90deg, #bbd0e7, #fff);
	padding: 0;
}
#eaton-2021 .section-03 p {
	background-color: #061122;
	padding: 20px 50px;
	text-transform: uppercase;
	color: #fff !important;
	font-size: 24px;
	width: auto;
	display: table;
	-ms-flex: none;
	flex: none;
}
#eaton-2021 .section-03 ul {
	margin: 10px 30px 30px;
}
#eaton-2021 .section-03 ul li {
	font-size: 24px;
	color: #061122;
	font-weight: 100;
}
#eaton-2021 .section-03 ul li:before {
	background: #061122;
}
#eaton-2021 .section-03 .page-col:nth-of-type(2) {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
#eaton-2021 .section-03 .page-col:nth-of-type(2) .responsive-video {
	width: 100%;
}
#eaton-2021 .five-steps {
	background: #f1f1f2;
}
#eaton-2021 .five-steps h2 {
	color: #000;
	width: 100%;
	text-align: center;
	margin-bottom: 30px;
}
#eaton-2021 .five-steps .btn {
	display: table;
	margin: 0 auto;
	padding: 7px 20px;
	color: #185dac;
	text-transform: uppercase;
	background-color: #fff;
	font-size: 16px;
	text-align: center;
}
#eaton-2021 .five-steps .btn:after {
	content: "";
	display: inline-block;
	margin-left: 10px;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #185dac;
}
#eaton-2021 .five-steps ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	#eaton-2021 .five-steps ul {
		-ms-flex-direction: column;
		flex-direction: column;
		margin-bottom: 30px;
	}
}
#eaton-2021 .five-steps ul li {
	width: 20%;
	padding: 0 10px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	#eaton-2021 .five-steps ul li {
		width: 100%;
		-ms-flex-direction: row;
		flex-direction: row;
	}
}
#eaton-2021 .five-steps ul li .number {
	width: 100px;
	height: 100px;
	-ms-flex: none;
	flex: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	color: #f1f1f2;
	font-size: 64px;
	line-height: 1;
	background-color: #6e9bd1;
}
#eaton-2021 .five-steps ul li .number span {
	margin-right: -4px;
	margin-bottom: -8px;
}
@media (max-width: 960px) {
	#eaton-2021 .five-steps ul li .number {
		margin-right: 30px;
	}
}
#eaton-2021 .five-steps ul li p {
	text-align: center;
	font-size: 16px;
	line-height: 1.3;
	margin-top: 10px;
}
@media (max-width: 960px) {
	#eaton-2021 .five-steps ul li p {
		text-align: left;
	}
	#eaton-2021 .five-steps ul li p br {
		display: none;
	}
}
#eaton-2021 .five-steps ul li:before {
	display: none;
}
#eaton-2021 .page-footer {
	padding: 15px 0 0;
	background: #2a2a2a;
}
#eaton-2021 .page-footer p {
	color: #fff !important;
	margin: 0 0 15px !important;
}
#eaton-2021 .page-footer .blue-rw {
	background: #185dac;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 20px;
}
#eaton-2021 .page-footer .blue-rw .sponsor-btn {
	color: #fff;
	text-transform: none;
	border-radius: 10px;
	padding: 10px 20px;
	border: none;
	-ms-flex: none;
	flex: none;
	background-color: #185dac;
	min-width: 0 !important;
}
#eaton-2021 .page-footer .blue-rw .sponsor-btn img {
	margin-right: 10px;
}
.specials-july-21 .orange,
.specials-july-21 p.page-title {
	color: #f58220 !important;
}
.specials-july-21 .special-section.bars {
	margin-top: 50px;
	border-top: 2px solid #1d4486;
	border-bottom: 2px solid #1d4486;
}
.specials-july-21 .white-bg .page-inner {
	background: #fff !important;
}
.specials-july-21 .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	.specials-july-21 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.specials-july-21 .flex-center .col {
	width: 33%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.specials-july-21 .flex-center .col {
		width: 100%;
	}
	.specials-july-21 .flex-center .col:not(:last-of-type) {
		margin-bottom: 100px;
	}
}
.specials-july-21 .flex-center .col img {
	width: 100%;
	max-width: 270px;
	height: auto;
}
.specials-july-21 p.page-title.orange {
	font-size: 28px;
}
.specials-july-21 p.orange-highlight {
	background: #f58220;
	color: #fff !important;
	padding: 10px 30px;
	margin-left: -30px;
	width: auto;
	display: table;
	font-size: 36px;
	position: relative;
	min-height: 66px;
	margin-bottom: 35px;
}
@media (max-width: 960px) {
	.specials-july-21 p.orange-highlight {
		width: calc(100% + 60px);
		text-align: center;
	}
}
.specials-july-21 p.orange-highlight:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	right: -33px;
	top: 0;
	border-top: 33px solid transparent;
	border-bottom: 33px solid transparent;
	border-left: 33px solid #f58220;
}
@media (max-width: 960px) {
	.specials-july-21 p.orange-highlight:after {
		display: none;
	}
}
.specials-july-21 .page-small-button {
	margin: 0 auto;
}
#atlas-2021 .page-subheader {
	background: #fff;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 20px 30px;
}
#atlas-2021 .page-subheader img {
	width: 200px;
}
#atlas-2021 .page-subheader .sponsor-btn {
	background: #ed1b2d;
	color: #fff;
	font-size: 18px;
	border: none;
	font-weight: 400;
}
#atlas-2021 .page-subheader .sponsor-btn:after {
	content: "";
	width: 10px;
	height: 10px;
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	display: block;
	transform: rotate(45deg);
	margin-left: 10px;
}
#atlas-2021 .hero {
	height: 300px;
	background-size: cover;
	background-position: 50%;
	position: relative;
}
#atlas-2021 .hero h1 {
	position: absolute;
	z-index: 4;
	bottom: 0;
	left: 0;
	padding: 10px 20px;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	margin: 0;
	font-size: 36px;
	font-weight: 400;
	color: #fff;
	text-transform: uppercase;
}
#atlas-2021 .section-03 h2 {
	color: #000;
	text-transform: none;
	font-weight: 100;
	font-size: 28px !important;
}
#atlas-2021 .section-03 .page-col:first-of-type {
	width: 65%;
	margin-right: 30px;
}
#atlas-2021 .section-03 .page-col:first-of-type ul {
	border-left: 5px solid #ed1b2d;
	padding-left: 30px;
	margin-top: 20px;
}
#atlas-2021 .section-03 .page-col:first-of-type ul li:before {
	background: #000;
}
@media (max-width: 960px) {
	#atlas-2021 .section-03 .page-col:first-of-type {
		width: 100%;
	}
}
#atlas-2021 .section-03 .page-col:first-of-type .video-preview {
	margin-top: 25px;
}
#atlas-2021 .section-03 .page-col:nth-of-type(2) {
	width: 35%;
	padding: 20px;
}
@media (max-width: 960px) {
	#atlas-2021 .section-03 .page-col:nth-of-type(2) {
		width: 100%;
	}
}
#atlas-2021 .section-03 .page-col:nth-of-type(2) ul li:before {
	background-color: #000;
}
#atlas-2021 .section-03 .page-col:nth-of-type(2) img {
	width: 80%;
	height: auto;
	margin: 0 auto 30px;
	display: block;
}
@media (max-width: 960px) {
	#atlas-2021 .section-03 .page-col:nth-of-type(2) img {
		max-width: 300px;
	}
}
#atlas-2021 .section-04 {
	-ms-flex-direction: column;
	flex-direction: column;
	padding-top: 0;
}
#atlas-2021 .section-04 .page-col:first-of-type {
	background: #ed1b2d;
	padding: 30px;
}
#atlas-2021 .section-04 .page-col:first-of-type h3 {
	text-align: center;
	color: #fff;
	font-size: 28px;
	font-weight: 100;
	margin: 0;
}
#atlas-2021 .section-04 .page-col:nth-of-type(2) {
	display: -ms-flexbox;
	display: flex;
	border: 3px solid #ed1b2d;
	padding: 10px;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	#atlas-2021 .section-04 .page-col:nth-of-type(2) {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}
}
#atlas-2021 .section-04 .page-col:nth-of-type(2) img {
	-ms-flex: None;
	flex: None;
}
#atlas-2021 .section-04 .page-col:nth-of-type(2) img:first-of-type {
	width: 150px;
	height: auto;
}
@media (max-width: 960px) {
	#atlas-2021 .section-04 .page-col:nth-of-type(2) img:first-of-type {
		width: 50%;
	}
}
#atlas-2021 .section-04 .page-col:nth-of-type(2) img:nth-of-type(2) {
	width: 185px;
	height: auto;
	margin-left: 10px;
}
@media (max-width: 960px) {
	#atlas-2021 .section-04 .page-col:nth-of-type(2) img:nth-of-type(2) {
		width: 80%;
		margin: 30px auto 0;
		max-width: 300px;
		-ms-flex-order: -1;
		order: -1;
	}
}
#atlas-2021 .section-04 .page-col:nth-of-type(2) img:nth-of-type(3) {
	width: 150px;
	height: auto;
}
@media (max-width: 960px) {
	#atlas-2021 .section-04 .page-col:nth-of-type(2) img:nth-of-type(3) {
		width: 50%;
		-ms-flex-order: -1;
		order: -1;
	}
}
#atlas-2021 .section-04 .page-col:nth-of-type(2) p {
	width: 50%;
	margin: 0 20px;
}
@media (max-width: 960px) {
	#atlas-2021 .section-04 .page-col:nth-of-type(2) p {
		width: 100%;
		-ms-flex-order: -1;
		order: -1;
		margin: 20px;
	}
}
#atlas-2021 .section-04 .page-col:nth-of-type(3) {
	background: #002e5d;
	padding: 30px;
	margin-top: 10px;
}
#atlas-2021 .section-04 .page-col:nth-of-type(3) p {
	color: #fff !important;
	text-align: center;
}
#atlas-2021 .section-04 .page-col:nth-of-type(3) a {
	margin: 30px auto 0;
	border: 1px solid #fff;
	color: #fff;
	background: #0d5287;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
	font-size: 18px;
	max-width: 350px;
}
@media (max-width: 960px) {
	#atlas-2021 .section-04 .page-col:nth-of-type(3) a {
		display: block;
		white-space: inherit;
	}
}
#atlas-2021 .section-04 .page-col:nth-of-type(3) a:after {
	content: "";
	width: 10px;
	height: 10px;
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	display: block;
	transform: rotate(45deg);
	margin-left: 10px;
}
@media (max-width: 960px) {
	#atlas-2021 .section-04 .page-col:nth-of-type(3) a:after {
		display: none;
	}
}
#atlas-2021 .page-footer {
	background: #e3e3e3;
	border-top: none;
}
#atlas-2021 .page-footer p {
	color: #000 !important;
	margin: 0 0 15px !important;
}
#atlas-2021 .page-footer .sponsor-btn {
	background: #ed1b2d;
	color: #fff;
	font-size: 18px;
	border: none;
	font-weight: 400;
	width: auto;
	display: -ms-flexbox;
	display: flex;
	max-width: auto;
	min-width: auto;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 175px;
}
#atlas-2021 .page-footer .sponsor-btn:after {
	content: "";
	width: 10px;
	height: 10px;
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	display: block;
	transform: rotate(45deg);
	margin-left: 10px;
}
#cooper-halo-2021 .page-subheader {
	background: #fff;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 20px 30px;
}
#cooper-halo-2021 .page-subheader img {
	width: 250px;
}
#cooper-halo-2021 .page-subheader .sponsor-btn {
	background: #d1412d;
	color: #fff;
	font-size: 18px;
	border: none;
	font-weight: 400;
	border-radius: 50px;
}
#cooper-halo-2021 .hero {
	height: 400px;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .hero {
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-direction: column;
		flex-direction: column;
		height: auto;
	}
}
#cooper-halo-2021 .hero h1 {
	max-width: 400px;
	font-size: 42px;
	margin: 100px 0 0;
	padding: 0;
	font-weight: 400;
	color: #fff;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .hero h1 {
		text-align: center;
	}
}
#cooper-halo-2021 .hero .col {
	background-size: cover;
	background-position: 50%;
	width: calc(100% - 250px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .hero .col {
		width: 100%;
	}
}
#cooper-halo-2021 .hero .can-col {
	width: 300px;
	height: auto;
	margin-left: -50px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .hero .can-col {
		margin-left: 0;
		width: 100%;
	}
}
#cooper-halo-2021 .hero .can-col img {
	width: 100%;
	height: auto;
	-ms-flex: none;
	flex: none;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .hero .can-col img {
		width: 90%;
		max-width: 350px;
		display: block;
		margin: 30px auto 0;
	}
}
#cooper-halo-2021 .intro-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
	padding: 30px;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .intro-section {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#cooper-halo-2021 .intro-section p {
	font-size: 32px;
	text-align: left;
	padding-right: 30px;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .intro-section p {
		padding-right: 0;
		text-align: center;
	}
}
#cooper-halo-2021 .section-01,
#cooper-halo-2021 .section-02 {
	-ms-flex-align: stretch;
	align-items: stretch;
	padding: 0;
	padding-bottom: 15px;
	background: #fff !important;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-01,
	#cooper-halo-2021 .section-02 {
		padding-bottom: 0;
	}
}
#cooper-halo-2021 .section-01 .page-col:first-of-type,
#cooper-halo-2021 .section-02 .page-col:first-of-type {
	width: calc(50% - 7.5px);
	margin-right: 15px;
}
#cooper-halo-2021 .section-01 .page-col:first-of-type img,
#cooper-halo-2021 .section-02 .page-col:first-of-type img {
	width: 100%;
	margin: 0 auto;
	display: block;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-01 .page-col:first-of-type,
	#cooper-halo-2021 .section-02 .page-col:first-of-type {
		width: 100%;
		margin-bottom: 15px;
	}
}
#cooper-halo-2021 .section-01 .page-col:nth-of-type(2),
#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) {
	width: calc(50% - 7.5px);
	background-color: #3d3935;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-01 .page-col:nth-of-type(2),
	#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) {
		width: 100%;
	}
}
#cooper-halo-2021 .section-01 .page-col:nth-of-type(2) p,
#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) p {
	color: #fff !important;
}
#cooper-halo-2021 .section-01 .page-col:nth-of-type(2) p,
#cooper-halo-2021 .section-01 .page-col:nth-of-type(2) ul li,
#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) p,
#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) ul li {
	font-size: 22px;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-02 {
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
}
#cooper-halo-2021 .section-02 .page-col:first-of-type {
	background-color: #55514e;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-02 .page-col:first-of-type {
		margin-bottom: 0;
		margin-top: 15px;
	}
}
#cooper-halo-2021 .section-02 .page-col:first-of-type p {
	color: #fff !important;
}
#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) {
	background-color: #fff;
	padding: 0;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) {
		margin-top: 15px;
	}
}
#cooper-halo-2021 .section-02 .page-col:nth-of-type(2) img {
	width: 100%;
	margin: 0 auto;
	display: block;
}
#cooper-halo-2021 .section-03 .page-col:first-of-type {
	width: 65%;
	margin-right: 30px;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-03 .page-col:first-of-type {
		width: 100%;
		margin-right: 0;
	}
}
#cooper-halo-2021 .section-03 .page-col:nth-of-type(2) {
	width: 35%;
}
#cooper-halo-2021 .section-03 .page-col:nth-of-type(2) img {
	width: 100%;
	height: auto;
	max-width: 300px;
	margin: 0 auto;
	display: block;
}
@media (max-width: 960px) {
	#cooper-halo-2021 .section-03 .page-col:nth-of-type(2) {
		width: 100%;
	}
}
#cooper-halo-2021 .section-03 p.title {
	color: #d1412d !important;
	font-weight: 700;
}
#cooper-halo-2021 .section-03 p.title:after {
	content: "";
	width: 100px;
	height: 1px;
	background-color: #d1412d;
	display: block;
	margin: 25px 0 15px;
}
#cooper-halo-2021 ul li:before {
	background: #000;
}
#cooper-halo-2021 .page-footer {
	background: #7d7369;
	border-top: none;
}
#cooper-halo-2021 .page-footer p {
	color: #fff !important;
	margin: 0 0 15px !important;
}
#cooper-halo-2021 .page-footer .sponsor-btn {
	background: #d1412d;
	color: #fff;
	font-size: 18px;
	border: none;
	font-weight: 400;
	width: auto;
	display: -ms-flexbox;
	display: flex;
	max-width: auto;
	min-width: auto;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 175px;
	border-radius: 50px;
	text-transform: lowercase;
}
#cooper-2021 .page-subheader {
	background: #fff;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 20px 30px;
}
#cooper-2021 .page-subheader img {
	width: 250px;
}
#cooper-2021 .page-subheader .sponsor-btn {
	background: #d1412d;
	color: #fff;
	font-size: 18px;
	border: none;
	font-weight: 400;
	border-radius: 50px;
}
#cooper-2021 .hero {
	height: 300px;
	background-size: cover;
	background-position: 50%;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding: 30px;
}
@media (max-width: 960px) {
	#cooper-2021 .hero {
		-ms-flex-pack: center;
		justify-content: center;
	}
}
#cooper-2021 .hero h1 {
	max-width: 350px;
	font-size: 36px;
	margin: 0;
	padding: 0;
	font-weight: 400;
	color: #fff;
}
@media (max-width: 960px) {
	#cooper-2021 .hero h1 {
		text-align: center;
	}
}
#cooper-2021 .intro-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
	padding: 30px;
}
#cooper-2021 .intro-section p {
	font-size: 32px;
	text-align: center;
}
#cooper-2021 .section-02 {
	background: #eee;
	padding: 30px;
	-ms-flex-align: center;
	align-items: center;
}
#cooper-2021 .section-02 .page-col:first-of-type {
	width: 35%;
	margin-right: 30px;
}
#cooper-2021 .section-02 .page-col:first-of-type img {
	width: 100%;
	max-width: 350px;
	margin: 0 auto;
	display: block;
}
@media (max-width: 960px) {
	#cooper-2021 .section-02 .page-col:first-of-type {
		width: 100%;
	}
}
#cooper-2021 .section-02 .page-col:nth-of-type(2) {
	width: 65%;
}
@media (max-width: 960px) {
	#cooper-2021 .section-02 .page-col:nth-of-type(2) {
		width: 100%;
	}
}
#cooper-2021 .section-02 .page-col:nth-of-type(2) ul li:before {
	background-color: #000;
}
#cooper-2021 .section-02 .page-col:nth-of-type(2) p:after {
	content: "";
	width: 100px;
	height: 1px;
	background: #d1412d;
	display: block;
	margin: 15px 0;
}
#cooper-2021 .section-02 .page-col:nth-of-type(2) p,
#cooper-2021 .section-02 .page-col:nth-of-type(2) ul li {
	font-size: 22px;
}
#cooper-2021 .section-03 .page-col {
	width: 80%;
	margin: 0 auto;
}
@media (max-width: 960px) {
	#cooper-2021 .section-03 .page-col {
		width: 100%;
	}
}
#cooper-2021 .page-footer {
	background: #3d3935;
	border-top: none;
}
#cooper-2021 .page-footer p {
	color: #fff !important;
	margin: 0 0 15px !important;
}
#cooper-2021 .page-footer .sponsor-btn {
	background: #d1412d;
	color: #fff;
	font-size: 18px;
	border: none;
	font-weight: 400;
	width: auto;
	display: -ms-flexbox;
	display: flex;
	max-width: auto;
	min-width: auto;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 175px;
	border-radius: 50px;
	text-transform: lowercase;
}
.featured-products.product {
	background: #fff;
	padding-bottom: 30px;
}
.featured-products .fp-header {
	background-image: url(/assets/images/2022/jan/imgs/hero-bg.jpg);
	background-size: cover;
	background-position: 50%;
	padding-bottom: 20px;
}
.featured-products .fp-header h1 {
	background-color: #f58220;
	color: #fff !important;
	margin: 0;
	padding: 10px 20px;
	width: 450px;
	min-height: 80px;
	font-size: 48px !important;
	position: relative;
}
@media (max-width: 960px) {
	.featured-products .fp-header h1 {
		width: calc(100% - 100px);
		line-height: 1 !important;
		font-size: 28px !important;
		height: 65px;
		min-height: auto;
		text-align: left;
		margin-top: 8px;
	}
}
.featured-products .fp-header h1 .triangle {
	position: absolute;
	top: 0;
	right: -30px;
	display: block;
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	border-left: 30px solid #f58220;
}
@media (max-width: 960px) {
	.featured-products .fp-header h1 .triangle {
		right: -20px;
		border-top: 32.5px solid transparent;
		border-bottom: 32.5px solid transparent;
		border-left: 20px solid #f58220;
	}
}
.featured-products .fp-header h1:before {
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	right: -200px;
	top: 36px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.featured-products .fp-header h1:before {
		display: none;
	}
}
.featured-products .fp-header h1:after {
	content: "";
	width: 200px;
	height: 1px;
	border-radius: 50%;
	position: absolute;
	right: -200px;
	top: 40px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.featured-products .fp-header h1:after {
		display: none;
	}
}
.featured-products .fp-header .brands {
	max-width: 800px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 30px auto 10px;
}
@media (max-width: 960px) {
	.featured-products .fp-header .brands {
		display: none;
	}
}
.featured-products .fp-header .brands a {
	width: calc((100% - 30px) / 3);
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
	cursor: pointer;
	background: #fff;
}
.featured-products .fp-header .brands a:not(:last-of-type) {
	margin-right: 20px;
}
.featured-products .fp-header .brands a img {
	width: 100%;
	height: auto;
}
.featured-products .fp-brand {
	position: relative;
	border-top: 10px solid #f58220;
}
.featured-products .fp-brand-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
	background: #fff;
	z-index: 4;
	position: relative;
}
@media (max-width: 960px) {
	.featured-products .fp-brand-header {
		-ms-flex-direction: column;
		flex-direction: column;
		padding-bottom: 30px;
	}
}
.featured-products .fp-brand-header p {
	color: #f58220 !important;
	margin: 0;
	padding-right: 50px;
}
@media (max-width: 960px) {
	.featured-products .fp-brand-header p {
		text-align: center;
		padding-right: 0;
	}
}
.featured-products .fp-brand-header .bolt {
	background-color: #f58220;
	padding: 10px 20px;
	width: 120px;
	height: 70px;
	position: relative;
}
@media (max-width: 960px) {
	.featured-products .fp-brand-header .bolt {
		display: none;
	}
}
.featured-products .fp-brand-header .bolt img {
	height: 100%;
	width: auto;
	margin-left: -10px;
}
.featured-products .fp-brand-header .bolt:before {
	content: "";
	position: absolute;
	top: 0;
	left: -25px;
	display: block;
	border-top: 35px solid transparent;
	border-bottom: 35px solid transparent;
	border-right: 25px solid #f58220;
}
.featured-products .fp-brand-header.product-page {
	border-top: 10px solid #f58220;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.featured-products .fp-brand-header.product-page {
		-ms-flex-pack: start;
		justify-content: flex-start;
		-ms-flex-direction: row;
		flex-direction: row;
		padding-bottom: 0;
		padding-left: 0;
	}
	.featured-products .fp-brand-header.product-page img {
		max-width: 290px;
		width: calc(100% - 150px);
	}
}
.featured-products .fp-brand-header.product-page .download-pdf {
	background-color: #f58220;
	padding: 10px 20px;
	width: 140px;
	height: 70px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	right: 0;
	color: #fff;
}
@media (max-width: 960px) {
	.featured-products .fp-brand-header.product-page .download-pdf {
		padding: 10px;
		width: 120px;
	}
}
.featured-products .fp-brand-header.product-page .download-pdf .triangle {
	position: absolute;
	top: 0;
	left: -25px;
	display: block;
	border-top: 35px solid transparent;
	border-bottom: 35px solid transparent;
	border-right: 25px solid #f58220;
}
.featured-products .fp-brand-header.product-page .download-pdf:before {
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	left: -150px;
	top: 31px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.featured-products .fp-brand-header.product-page .download-pdf:before {
		display: none;
	}
}
.featured-products .fp-brand-header.product-page .download-pdf:after {
	content: "";
	width: 150px;
	height: 1px;
	border-radius: 50%;
	position: absolute;
	left: -150px;
	top: 35px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.featured-products .fp-brand-header.product-page .download-pdf:after {
		display: none;
	}
}
.featured-products .fp-products {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 50px 20px;
	margin-bottom: 50px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
	background: #fff;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.featured-products .fp-products {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.featured-products .fp-products .fp-product {
	width: 315px;
	overflow: hidden;
	height: 230px;
	position: relative;
	text-decoration: none !important;
	cursor: pointer;
	border: 1px solid #e7e8e9;
	background: #0c4386;
	background: radial-gradient(at top left, #0c4386 25%, #236ec5 100%);
}
.featured-products .fp-products .fp-product:not(:last-of-type),
.featured-products .fp-products .fp-product:not(:nth-of-type(3n + 3)) {
	margin-right: 15px;
}
@media (max-width: 1024px) {
	.featured-products .fp-products .fp-product:not(:last-of-type),
	.featured-products .fp-products .fp-product:not(:nth-of-type(3n + 3)) {
		margin-right: 0;
	}
}
@media (max-width: 1024px) {
	.featured-products .fp-products .fp-product {
		width: 300px;
		height: 220px;
	}
}
@media (max-width: 960px) {
	.featured-products .fp-products .fp-product:not(:last-of-type) {
		margin-bottom: 30px;
	}
}
.featured-products .fp-products .fp-product .fp-eyebrow {
	position: absolute;
	height: 20px;
	margin-bottom: -20px;
	color: #f58220;
	text-align: right;
	width: 100%;
	text-transform: uppercase;
	padding: 7px 5px;
}
.featured-products .fp-products .fp-product p {
	color: #fff !important;
	text-align: center;
	font-size: 14px;
	padding: 0 10px;
}
.featured-products .fp-products .fp-product .title {
	background: hsla(0, 0%, 100%, 0.5);
	position: relative;
	width: calc(100% - 50px);
	margin-left: 50px;
	min-height: 40px;
	color: #fff;
	margin-top: -55px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-size: 16px;
	padding-left: 5px;
	margin-bottom: 10px;
	transition: margin-top 0.5s;
}
@media (max-width: 1024px) {
	.featured-products .fp-products .fp-product .title {
		margin-top: -50px;
		width: calc(100% - 30px);
		margin-left: 30px;
	}
}
.featured-products .fp-products .fp-product .title:before {
	content: "";
	position: absolute;
	top: 0;
	left: -15px;
	display: block;
	border-top: 20px solid transparent;
	border-bottom: 20px solid transparent;
	border-right: 15px solid hsla(0, 0%, 100%, 0.5);
}
.featured-products .fp-products .fp-product .fp-btn {
	color: #fff;
	display: table;
	margin: 20px auto 0;
	padding: 5px 10px;
	border-radius: 3px;
	background: #378dcd;
}
.featured-products .fp-products .fp-product img {
	width: 100%;
	height: auto;
	cursor: pointer;
	transition: margin-top 0.5s;
}
.featured-products .fp-products .fp-product:hover img {
	margin-top: -170px;
}
.featured-products .fp-products .fp-product:hover .title {
	margin-top: -20px;
}
.featured-products .fp-section {
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 15px;
}
.featured-products .fp-section.two-col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
}
@media (max-width: 960px) {
	.featured-products .fp-section.two-col {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.featured-products .fp-section.two-col .col {
	width: 50%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.featured-products .fp-section.two-col .col {
		width: 100%;
	}
}
.featured-products .fp-footer {
	background: #83c000;
	width: calc(100% - 60px - 20px);
	height: 130px;
	padding: 10px 20px 10px 80px;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.featured-products .fp-footer {
		padding: 20px;
		height: auto;
		width: 100%;
	}
}
.featured-products .fp-footer:after {
	position: absolute;
	content: "";
	top: 0;
	right: -60px;
	display: block;
	border-top: 65px solid transparent;
	border-bottom: 65px solid transparent;
	border-left: 60px solid #83c000;
}
@media (max-width: 960px) {
	.featured-products .fp-footer:after {
		display: none;
	}
}
.featured-products .fp-footer p {
	color: #fff !important;
	text-align: center;
	max-width: 500px;
}
.featured-products .fp-footer .fp-btn {
	border: 2px solid #fff;
	padding: 5px 20px;
	color: #fff;
	text-transform: uppercase;
}
.bx-wrapper .bx-controls-direction a {
	z-index: 1 !important;
}
#schedule-a-demo {
	width: 100%;
	position: fixed;
	bottom: -182px;
	left: 0;
	z-index: 5 !important;
	transition: bottom 1s;
}
@media (max-width: 960px) {
	#schedule-a-demo {
		bottom: -422px;
	}
}
#schedule-a-demo.disabled {
	display: none !important;
}
#schedule-a-demo.hovered {
	bottom: -162px;
}
@media (max-width: 960px) {
	#schedule-a-demo.hovered {
		bottom: -422px;
	}
}
#schedule-a-demo.active {
	bottom: 0 !important;
}
#schedule-a-demo .sch-tab {
	color: #fff;
	background-color: #f58220;
	border-radius: 0;
	padding: 10px 20px;
	margin: 0 auto;
	display: block;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 24px;
	cursor: pointer;
}
@media (max-width: 960px) {
	#schedule-a-demo .sch-tab {
		font-size: 14px;
	}
}
#schedule-a-demo .sch-tab span {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-left: 20px;
	text-align: center;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	transition: all 0.5s;
}
@media (max-width: 960px) {
	#schedule-a-demo .sch-tab span {
		margin-left: 10px;
	}
}
#schedule-a-demo .sch-tab span:after {
	display: inline-block;
	content: "";
	width: 7px;
	height: 7px;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #0c4487;
}
@media (max-width: 960px) {
	#schedule-a-demo .sch-tab span:after {
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid #0c4487;
	}
}
#schedule-a-demo.active .sch-tab span {
	transform: rotate(180deg);
}
#schedule-a-demo .inner {
	width: 100%;
	min-height: 120px;
	cursor: pointer;
	background: #1d4486;
	margin-top: -2px;
	padding: 20px;
}
#schedule-a-demo .inner form {
	max-width: 1020px;
	margin: 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
@media (max-width: 960px) {
	#schedule-a-demo .inner form {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
#schedule-a-demo .inner form .form-group {
	width: calc((100% - 20px) / 3);
}
@media (max-width: 960px) {
	#schedule-a-demo .inner form .form-group {
		width: 100%;
	}
}
#schedule-a-demo .inner form .form-group:nth-of-type(4) {
	width: calc(100% - 305px - 100px - 20px);
}
@media (max-width: 960px) {
	#schedule-a-demo .inner form .form-group:nth-of-type(4) {
		width: 100%;
	}
}
#schedule-a-demo .inner form .form-group:nth-of-type(5) {
	width: 305px;
}
@media (max-width: 960px) {
	#schedule-a-demo .inner form .form-group:nth-of-type(5) {
		width: 100%;
	}
	#schedule-a-demo .inner form .form-group:nth-of-type(5) div {
		margin: 0 auto;
		display: block;
	}
}
#schedule-a-demo .inner form .form-group:last-of-type {
	width: 100px;
}
@media (max-width: 960px) {
	#schedule-a-demo .inner form .form-group:last-of-type {
		width: 100%;
	}
}
#schedule-a-demo .inner form .form-group input,
#schedule-a-demo .inner form .form-group textarea {
	border-radius: 0;
}
#schedule-a-demo .inner form .form-group input[type="submit"],
#schedule-a-demo .inner form .form-group textarea[type="submit"] {
	background-color: #f58220;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	border: none;
	width: 100px;
}
@media (max-width: 960px) {
	#schedule-a-demo .inner form .form-group input[type="submit"],
	#schedule-a-demo .inner form .form-group textarea[type="submit"] {
		width: 100%;
	}
}
#schedule-a-demo .inner form .form-group textarea {
	min-height: 78px;
}
.leviton-commercial-22,
.squared-wiser-22 {
	background: #fff;
	padding-bottom: 30px;
}
.leviton-commercial-22 .page-image-hero h1,
.squared-wiser-22 .page-image-hero h1 {
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.leviton-commercial-22 .fp-header,
.squared-wiser-22 .fp-header {
	background-image: url(/assets/images/2022/jan/imgs/hero-bg.jpg);
	background-size: cover;
	background-position: 50%;
	padding-bottom: 20px;
	border-bottom: 10px solid #f58220;
}
.leviton-commercial-22 .fp-header h1,
.squared-wiser-22 .fp-header h1 {
	background-color: #f58220;
	color: #fff !important;
	margin: 0;
	padding: 10px 20px;
	width: 450px;
	min-height: 80px;
	font-size: 48px !important;
	position: relative;
}
@media (max-width: 960px) {
	.leviton-commercial-22 .fp-header h1,
	.squared-wiser-22 .fp-header h1 {
		width: calc(100% - 100px);
		line-height: 0 !important;
		font-size: 28px !important;
		height: 65px;
		min-height: auto;
		text-align: left;
		margin-top: 8px;
	}
}
.leviton-commercial-22 .fp-header h1 .triangle,
.squared-wiser-22 .fp-header h1 .triangle {
	position: absolute;
	top: 0;
	right: -30px;
	display: block;
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	border-left: 30px solid #f58220;
}
@media (max-width: 960px) {
	.leviton-commercial-22 .fp-header h1 .triangle,
	.squared-wiser-22 .fp-header h1 .triangle {
		right: -20px;
		border-top: 32.5px solid transparent;
		border-bottom: 32.5px solid transparent;
		border-left: 20px solid #f58220;
	}
}
.leviton-commercial-22 .fp-header h1:before,
.squared-wiser-22 .fp-header h1:before {
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	right: -200px;
	top: 36px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.leviton-commercial-22 .fp-header h1:before,
	.squared-wiser-22 .fp-header h1:before {
		display: none;
	}
}
.leviton-commercial-22 .fp-header h1:after,
.squared-wiser-22 .fp-header h1:after {
	content: "";
	width: 200px;
	height: 1px;
	border-radius: 50%;
	position: absolute;
	right: -200px;
	top: 40px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.leviton-commercial-22 .fp-header h1:after,
	.squared-wiser-22 .fp-header h1:after {
		display: none;
	}
}
.leviton-commercial-22 .fp-header .brands,
.squared-wiser-22 .fp-header .brands {
	max-width: 800px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 30px auto 10px;
}
@media (max-width: 960px) {
	.leviton-commercial-22 .fp-header .brands,
	.squared-wiser-22 .fp-header .brands {
		display: none;
	}
}
.leviton-commercial-22 .fp-header .brands a,
.squared-wiser-22 .fp-header .brands a {
	width: calc((100% - 30px) / 3);
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
	cursor: pointer;
}
.leviton-commercial-22 .fp-header .brands a img,
.squared-wiser-22 .fp-header .brands a img {
	width: 100%;
	height: auto;
}
.leviton-commercial-22 .fp-footer,
.squared-wiser-22 .fp-footer {
	background: #83c000;
	width: calc(100% - 60px - 20px);
	height: 130px;
	padding: 10px 20px 10px 80px;
	margin: 30px 0 0;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.leviton-commercial-22 .fp-footer,
	.squared-wiser-22 .fp-footer {
		padding: 20px;
		height: auto;
		width: 100%;
	}
}
.leviton-commercial-22 .fp-footer:after,
.squared-wiser-22 .fp-footer:after {
	position: absolute;
	content: "";
	top: 0;
	right: -60px;
	display: block;
	border-top: 65px solid transparent;
	border-bottom: 65px solid transparent;
	border-left: 60px solid #83c000;
}
@media (max-width: 960px) {
	.leviton-commercial-22 .fp-footer:after,
	.squared-wiser-22 .fp-footer:after {
		display: none;
	}
}
.leviton-commercial-22 .fp-footer p,
.squared-wiser-22 .fp-footer p {
	color: #fff !important;
	text-align: center;
	max-width: 500px;
}
.leviton-commercial-22 .fp-footer .fp-btn,
.squared-wiser-22 .fp-footer .fp-btn {
	border: 2px solid #fff;
	padding: 5px 20px;
	color: #fff;
	text-transform: uppercase;
}
#inform-remote-monitoring .fp-hero {
	padding: 0 5%;
	background-size: cover;
	background-position: 100%;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 768px) {
	#inform-remote-monitoring .fp-hero {
		padding: 30px;
	}
}
#inform-remote-monitoring .fp-hero .col img {
	width: 100%;
	max-width: 350px;
	height: auto;
	margin-bottom: 20px;
}
#inform-remote-monitoring .fp-hero .col p {
	color: #fff !important;
	font-size: 24px;
	max-width: 520px;
}
#inform-remote-monitoring .fp-hero .product-image {
	width: 100%;
	max-width: 300px;
	height: auto;
	margin: 30px 0 -150px;
}
@media (max-width: 768px) {
	#inform-remote-monitoring .fp-hero .product-image {
		margin: 30px auto 0;
	}
}
@media (max-width: 960px) {
	#inform-remote-monitoring .fp-hero,
	#inform-remote-monitoring .section-02 {
		-ms-flex-direction: row;
		flex-direction: row;
	}
}
@media (max-width: 768px) {
	#inform-remote-monitoring .fp-hero,
	#inform-remote-monitoring .section-02 {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#inform-remote-monitoring .fp-hero .col:first-of-type,
#inform-remote-monitoring .section-02 .col:first-of-type {
	width: 60%;
}
@media (max-width: 768px) {
	#inform-remote-monitoring .fp-hero .col:first-of-type,
	#inform-remote-monitoring .section-02 .col:first-of-type {
		width: 100%;
	}
}
#inform-remote-monitoring .section-02 {
	width: 90%;
	margin: 30px auto;
	display: block;
}
#inform-remote-monitoring .section-02 p {
	margin: 0;
}
#inform-remote-monitoring .section-03 .col {
	width: 90%;
	border: 2px solid #83c000;
	border-radius: 0 0 0 50px;
	padding: 40px;
	margin: 30px auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	#inform-remote-monitoring .section-03 .col {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#inform-remote-monitoring .section-03 .col img {
	width: 70%;
	height: auto;
}
@media (max-width: 960px) {
	#inform-remote-monitoring .section-03 .col img {
		margin: 0 auto;
	}
}
#inform-remote-monitoring .section-03 .col .inner p {
	color: #1d4486 !important;
}
#inform-remote-monitoring .section-03 .col .inner p.sml {
	font-size: 1em;
	text-align: center;
	margin-top: 30px;
}
#inform-remote-monitoring .section-03 .col .inner img {
	max-width: 150px;
	width: 60%;
	height: auto;
	margin: 0 auto;
	display: block;
}
#inform-remote-monitoring .section-04 .col:nth-of-type(2) {
	background: #eaeaea;
	padding: 30px;
}
#inform-remote-monitoring .section-04 .col:nth-of-type(2) ul li:before {
	background-color: #83c000;
}
#decora-smart .fp-hero {
	padding: 30px 5% 0;
	background-size: cover;
	background-position: 100%;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-direction: column;
	flex-direction: column;
}
@media (max-width: 768px) {
	#decora-smart .fp-hero {
		padding: 30px;
		margin-bottom: 165px;
	}
}
#decora-smart .fp-hero .col {
	width: 100% !important;
}
#decora-smart .fp-hero .col img {
	width: 100%;
	max-width: 350px;
	height: auto;
	margin-bottom: 20px;
}
#decora-smart .fp-hero .col h1 {
	color: #fff !important;
	font-size: 36px;
	text-align: center;
	max-width: 500px;
	font-weight: 100;
	margin: 0 auto;
	display: block;
}
#decora-smart .fp-hero .col h1:after {
	content: "";
	width: 50px;
	margin: 20px auto;
	display: block;
	height: 2px;
	background: #83c000;
}
#decora-smart .fp-hero .col p {
	color: #fff !important;
	font-size: 18px;
	text-align: center;
}
#decora-smart .fp-hero .col:nth-of-type(2) {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
}
#decora-smart .fp-hero .col:nth-of-type(2) .product-image {
	width: 100%;
	max-width: 250px;
	height: auto;
	margin: -75px 0 -200px;
}
@media (max-width: 768px) {
	#decora-smart .fp-hero .col:nth-of-type(2) .product-image {
		margin: 30px auto -150px;
	}
}
@media (max-width: 960px) {
	#decora-smart .fp-hero,
	#decora-smart .section-02 {
		-ms-flex-direction: row;
		flex-direction: row;
	}
}
@media (max-width: 768px) {
	#decora-smart .fp-hero,
	#decora-smart .section-02 {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#decora-smart .fp-hero .col:first-of-type,
#decora-smart .section-02 .col:first-of-type {
	width: 60%;
}
@media (max-width: 768px) {
	#decora-smart .fp-hero .col:first-of-type,
	#decora-smart .section-02 .col:first-of-type {
		width: 100%;
	}
}
#decora-smart .section-02 {
	width: 90%;
	margin: 30px auto 100px;
	display: block;
}
@media (max-width: 768px) {
	#decora-smart .section-02 {
		margin: 30px auto;
	}
}
#decora-smart .section-02 p {
	margin: 0;
	font-size: 16px;
}
#decora-smart .section-03 {
	margin-top: 50px;
	width: 100%;
	display: block;
}
@media (max-width: 768px) {
	#decora-smart .section-03 {
		margin-top: 0;
	}
}
#decora-smart .section-03 .col {
	width: 100%;
}
#decora-smart .section-03 .col img {
	width: 70%;
	height: auto;
	margin: 0 auto;
	display: block;
}
@media (max-width: 768px) {
	#decora-smart .section-03 .col img {
		width: calc(100% - 30px);
	}
}
#decora-smart .section-03 .col p {
	color: #1d4486 !important;
	text-align: center;
	margin: 30px 0;
}
#decora-smart .section-04 .col:nth-of-type(2) {
	background: #353535;
	padding: 30px 75px;
}
@media (max-width: 768px) {
	#decora-smart .section-04 .col:nth-of-type(2) {
		padding: 30px;
	}
}
#decora-smart .section-04 .col:nth-of-type(2) ul li {
	color: #fff !important;
}
#decora-smart .section-04 .col:nth-of-type(2) ul li:before {
	background-color: #83c000;
}
#decora-smart .section-05 {
	max-width: 80%;
	margin: 50px auto;
}
@media (max-width: 1024px) {
	#decora-smart .section-05 {
		width: 100%;
	}
}
@media (max-width: 960px) {
	#decora-smart .section-05 {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
#decora-smart .section-05 .col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: start;
	justify-content: flex-start;
}
#decora-smart .section-05 .col:first-of-type,
#decora-smart .section-05 .col:nth-of-type(3),
#decora-smart .section-05 .col:nth-of-type(5) {
	width: calc((100% - 200px) / 3);
}
@media (max-width: 960px) {
	#decora-smart .section-05 .col:first-of-type,
	#decora-smart .section-05 .col:nth-of-type(3),
	#decora-smart .section-05 .col:nth-of-type(5) {
		width: 100%;
		max-width: 350px;
	}
}
#decora-smart .section-05 .col:nth-of-type(2),
#decora-smart .section-05 .col:nth-of-type(4) {
	width: 100px;
}
@media (max-width: 960px) {
	#decora-smart .section-05 .col:nth-of-type(2) img,
	#decora-smart .section-05 .col:nth-of-type(4) img {
		max-height: 150px;
	}
}
#decora-smart .section-05 .col img {
	max-height: 220px;
	width: auto;
}
#decora-smart .section-05 .col span {
	width: 25px;
	height: 25px;
	background: #83c000;
	border-radius: 50%;
	color: #fff;
	font-size: 14px;
	line-height: 0;
	margin: 15px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
#decora-smart .section-05 .col p {
	max-width: 100%;
	margin: 0 auto;
	font-size: 14px;
	color: #1d4486 !important;
	text-align: center;
}
@media (max-width: 960px) {
	#decora-smart .section-05 .col p {
		font-size: 16px;
	}
}
#dual-function .fp-footer,
#ez-meter-pak .fp-footer,
.squared-wiser-22 .fp-footer {
	background: #404040;
}
#dual-function .fp-footer:after,
#ez-meter-pak .fp-footer:after,
.squared-wiser-22 .fp-footer:after {
	border-left: 60px solid #404040;
}
#dual-function .fp-footer .fp-btn,
#ez-meter-pak .fp-footer .fp-btn,
.squared-wiser-22 .fp-footer .fp-btn {
	color: #fff;
	background: #42b4e6;
	border: none;
	text-transform: none;
}
#dual-function .fp-brand-header,
#ez-meter-pak .fp-brand-header,
.squared-wiser-22 .fp-brand-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
	background: #fff;
	z-index: 4;
	position: relative;
}
@media (max-width: 960px) {
	#dual-function .fp-brand-header,
	#ez-meter-pak .fp-brand-header,
	.squared-wiser-22 .fp-brand-header {
		-ms-flex-direction: column;
		flex-direction: column;
		padding-bottom: 30px;
	}
}
#dual-function .fp-brand-header p,
#ez-meter-pak .fp-brand-header p,
.squared-wiser-22 .fp-brand-header p {
	color: #f58220 !important;
	margin: 0;
	padding-right: 50px;
}
@media (max-width: 960px) {
	#dual-function .fp-brand-header p,
	#ez-meter-pak .fp-brand-header p,
	.squared-wiser-22 .fp-brand-header p {
		text-align: center;
		padding-right: 0;
	}
}
#dual-function .fp-brand-header .bolt,
#ez-meter-pak .fp-brand-header .bolt,
.squared-wiser-22 .fp-brand-header .bolt {
	background-color: #f58220;
	padding: 10px 20px;
	width: 120px;
	height: 70px;
	position: relative;
}
@media (max-width: 960px) {
	#dual-function .fp-brand-header .bolt,
	#ez-meter-pak .fp-brand-header .bolt,
	.squared-wiser-22 .fp-brand-header .bolt {
		display: none;
	}
}
#dual-function .fp-brand-header .bolt img,
#ez-meter-pak .fp-brand-header .bolt img,
.squared-wiser-22 .fp-brand-header .bolt img {
	height: 100%;
	width: auto;
	margin-left: -10px;
}
#dual-function .fp-brand-header .bolt:before,
#ez-meter-pak .fp-brand-header .bolt:before,
.squared-wiser-22 .fp-brand-header .bolt:before {
	content: "";
	position: absolute;
	top: 0;
	left: -25px;
	display: block;
	border-top: 35px solid transparent;
	border-bottom: 35px solid transparent;
	border-right: 25px solid #f58220;
}
#dual-function .fp-brand-header.product-page,
#ez-meter-pak .fp-brand-header.product-page,
.squared-wiser-22 .fp-brand-header.product-page {
	border-top: 10px solid #f58220;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	#dual-function .fp-brand-header.product-page,
	#ez-meter-pak .fp-brand-header.product-page,
	.squared-wiser-22 .fp-brand-header.product-page {
		-ms-flex-pack: start;
		justify-content: flex-start;
		-ms-flex-direction: row;
		flex-direction: row;
		padding-bottom: 0;
		padding-left: 0;
	}
	#dual-function .fp-brand-header.product-page img,
	#ez-meter-pak .fp-brand-header.product-page img,
	.squared-wiser-22 .fp-brand-header.product-page img {
		max-width: 290px;
		width: calc(100% - 150px);
	}
}
#dual-function .fp-brand-header.product-page .download-pdf,
#ez-meter-pak .fp-brand-header.product-page .download-pdf,
.squared-wiser-22 .fp-brand-header.product-page .download-pdf {
	background-color: #f58220;
	padding: 10px 20px;
	width: 140px;
	height: 70px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	right: 0;
	color: #fff;
}
@media (max-width: 960px) {
	#dual-function .fp-brand-header.product-page .download-pdf,
	#ez-meter-pak .fp-brand-header.product-page .download-pdf,
	.squared-wiser-22 .fp-brand-header.product-page .download-pdf {
		padding: 10px;
		width: 120px;
	}
}
#dual-function .fp-brand-header.product-page .download-pdf .triangle,
#ez-meter-pak .fp-brand-header.product-page .download-pdf .triangle,
.squared-wiser-22 .fp-brand-header.product-page .download-pdf .triangle {
	position: absolute;
	top: 0;
	left: -25px;
	display: block;
	border-top: 35px solid transparent;
	border-bottom: 35px solid transparent;
	border-right: 25px solid #f58220;
}
#dual-function .fp-brand-header.product-page .download-pdf:before,
#ez-meter-pak .fp-brand-header.product-page .download-pdf:before,
.squared-wiser-22 .fp-brand-header.product-page .download-pdf:before {
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	left: -150px;
	top: 31px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	#dual-function .fp-brand-header.product-page .download-pdf:before,
	#ez-meter-pak .fp-brand-header.product-page .download-pdf:before,
	.squared-wiser-22 .fp-brand-header.product-page .download-pdf:before {
		display: none;
	}
}
#dual-function .fp-brand-header.product-page .download-pdf:after,
#ez-meter-pak .fp-brand-header.product-page .download-pdf:after,
.squared-wiser-22 .fp-brand-header.product-page .download-pdf:after {
	content: "";
	width: 150px;
	height: 1px;
	border-radius: 50%;
	position: absolute;
	left: -150px;
	top: 35px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	#dual-function .fp-brand-header.product-page .download-pdf:after,
	#ez-meter-pak .fp-brand-header.product-page .download-pdf:after,
	.squared-wiser-22 .fp-brand-header.product-page .download-pdf:after {
		display: none;
	}
}
#ez-meter-pak {
	background-color: #f6f6f6;
}
#ez-meter-pak .fp-hero {
	background: #404040;
}
@media (max-width: 960px) {
	#ez-meter-pak .fp-hero {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#ez-meter-pak .fp-hero .col:first-of-type {
	width: 60%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 30px;
}
@media (max-width: 960px) {
	#ez-meter-pak .fp-hero .col:first-of-type {
		width: 100%;
		padding: 20px;
	}
}
#ez-meter-pak .fp-hero .col:first-of-type h1 {
	color: #fff;
	font-size: 48px;
	margin: 0 0 5px;
	max-width: 350px;
}
#ez-meter-pak .fp-hero .col:first-of-type .subtitle {
	color: #3dcd58 !important;
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 20px;
}
#ez-meter-pak .fp-hero .col:first-of-type p {
	color: #fff !important;
	font-size: 16px;
	font-weight: 100;
}
#ez-meter-pak .fp-hero .col:nth-of-type(2) {
	width: 40%;
}
@media (max-width: 960px) {
	#ez-meter-pak .fp-hero .col:nth-of-type(2) {
		width: 100%;
	}
}
#ez-meter-pak .fp-hero .col:nth-of-type(2) img {
	width: 100%;
	height: auto;
}
#ez-meter-pak .section-02 {
	background-color: #f6f6f6;
	padding: 20px 30px 30px;
	margin: 0;
	width: 100%;
	position: relative;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
@media (max-width: 960px) {
	#ez-meter-pak .section-02 {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#ez-meter-pak .section-02 .col {
	background: #fff;
	border: 1px solid #eaeaea;
	padding: 20px;
	-ms-flex: none;
	flex: none;
}
#ez-meter-pak .section-02 .col:first-of-type,
#ez-meter-pak .section-02 .col:nth-of-type(2) {
	width: calc(50% - 15px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	#ez-meter-pak .section-02 .col:first-of-type,
	#ez-meter-pak .section-02 .col:nth-of-type(2) {
		width: 100%;
	}
}
@media (max-width: 768px) {
	#ez-meter-pak .section-02 .col:first-of-type,
	#ez-meter-pak .section-02 .col:nth-of-type(2) {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#ez-meter-pak .section-02 .col:first-of-type p.sml,
#ez-meter-pak .section-02 .col:nth-of-type(2) p.sml {
	font-size: 16px;
}
#ez-meter-pak .section-02 .col:first-of-type ul li:before,
#ez-meter-pak .section-02 .col:nth-of-type(2) ul li:before {
	background-color: #58595b !important;
}
@media (max-width: 768px) {
	#ez-meter-pak .section-02 .col:first-of-type {
		margin-bottom: 20px;
	}
}
#ez-meter-pak .section-02 .col:first-of-type img {
	width: 125px;
	height: auto;
	margin-right: 20px;
}
@media (max-width: 768px) {
	#ez-meter-pak .section-02 .col:first-of-type img {
		margin: 20px 0;
	}
}
#ez-meter-pak .section-02 .col:nth-of-type(3) {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 30px;
}
@media (max-width: 960px) {
	#ez-meter-pak .section-02 .col:nth-of-type(3) {
		width: 100%;
		margin: 20px 0;
	}
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center {
	border: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 768px) {
	#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl {
		width: 100%;
	}
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl:first-of-type {
	padding-right: 20px;
}
@media (max-width: 960px) {
	#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl:first-of-type {
		padding-right: 0;
		margin-bottom: 20px;
	}
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl img {
	width: 40px;
	height: auto;
	margin-bottom: 10px;
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl p {
	font-size: 16px;
	text-align: center;
	width: 100%;
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl ul li {
	text-align: center;
	padding: 0;
	margin: 0;
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl ul li:before {
	display: none;
}
#ez-meter-pak .section-02 .col:nth-of-type(3) .flex-center .cl ul li:not(:last-of-type):after {
	width: 5px;
	height: 5px;
	display: block;
	margin: 10px auto;
	border-radius: 50%;
	background-color: #3dcd58;
	content: "";
}
#dual-function {
	background-color: #f6f6f6;
}
#dual-function .fp-hero {
	-ms-flex-direction: column;
	flex-direction: column;
	background: #fff;
}
#dual-function .fp-hero img {
	width: 100%;
	height: auto;
}
#dual-function .fp-hero .flex {
	display: -ms-flexbox;
	display: flex;
	width: 90%;
	margin: 0 auto;
}
@media (max-width: 768px) {
	#dual-function .fp-hero .flex {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#dual-function .fp-hero .flex .col:first-of-type {
	width: calc(100% - 200px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 768px) {
	#dual-function .fp-hero .flex .col:first-of-type {
		width: 100%;
		padding: 20px;
	}
}
#dual-function .fp-hero .flex .col:first-of-type h1 {
	color: #3dcd58;
	font-size: 36px;
	margin: 0 0 5px;
}
#dual-function .fp-hero .flex .col:nth-of-type(2) {
	width: 200px;
}
@media (max-width: 768px) {
	#dual-function .fp-hero .flex .col:nth-of-type(2) {
		width: 100%;
	}
}
#dual-function .fp-hero .flex .col:nth-of-type(2) img {
	width: 100%;
	height: auto;
	margin-top: -150px;
}
@media (max-width: 768px) {
	#dual-function .fp-hero .flex .col:nth-of-type(2) img {
		margin-top: 0;
		max-width: 200px;
		margin: 0 auto;
		display: block;
	}
}
#dual-function .section-02 {
	background-color: #f6f6f6;
	padding: 20px 30px 30px;
	margin: 0;
	width: 100%;
	position: relative;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	#dual-function .section-02 {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
#dual-function .section-02 .col {
	background: #fff;
	border: 1px solid #eaeaea;
	padding: 20px;
	-ms-flex: none;
	flex: none;
}
#dual-function .section-02 .col:first-of-type,
#dual-function .section-02 .col:nth-of-type(3) {
	width: 25%;
}
@media (max-width: 960px) {
	#dual-function .section-02 .col:first-of-type,
	#dual-function .section-02 .col:nth-of-type(3) {
		width: 100%;
	}
}
#dual-function .section-02 .col:first-of-type p.sml,
#dual-function .section-02 .col:nth-of-type(3) p.sml {
	font-size: 16px;
}
#dual-function .section-02 .col:first-of-type ul li:before,
#dual-function .section-02 .col:nth-of-type(3) ul li:before {
	background-color: #58595b !important;
}
#dual-function .section-02 .col:nth-of-type(2) {
	width: calc(50% - 40px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	#dual-function .section-02 .col:nth-of-type(2) {
		width: 100%;
		margin: 20px 0;
	}
}
#dual-function .section-02 .col:nth-of-type(2) img {
	width: 100%;
	height: auto;
}
#dual-function .section-03 {
	background-color: #f6f6f6;
	padding: 0 30px 30px;
	margin: 0;
	width: 100%;
}
#dual-function .section-03 .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
	border: 1px solid #eaeaea;
	padding: 20px;
}
@media (max-width: 960px) {
	#dual-function .section-03 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}
}
#dual-function .section-03 .flex-center .col {
	width: 50%;
}
@media (max-width: 960px) {
	#dual-function .section-03 .flex-center .col {
		width: 100%;
	}
}
#dual-function .section-03 .flex-center .col:nth-of-type(2) {
	padding-left: 30px;
}
@media (max-width: 960px) {
	#dual-function .section-03 .flex-center .col:nth-of-type(2) {
		padding-left: 0;
	}
}
#dual-function .section-03 .flex-center .col:nth-of-type(2) .flex-center {
	border: none;
}
#dual-function .section-03 .flex-center .col:nth-of-type(2) .flex-center .cl {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	#dual-function .section-03 .flex-center .col:nth-of-type(2) .flex-center .cl {
		width: 100%;
	}
}
#dual-function .section-03 .flex-center .col:nth-of-type(2) .flex-center .cl:first-of-type {
	padding-right: 20px;
}
@media (max-width: 960px) {
	#dual-function .section-03 .flex-center .col:nth-of-type(2) .flex-center .cl:first-of-type {
		padding-right: 0;
		margin-bottom: 20px;
	}
}
#dual-function .section-03 .flex-center .col:nth-of-type(2) .flex-center .cl img {
	width: 40px;
	height: auto;
	margin-bottom: 10px;
}
#dual-function .section-03 .flex-center .col:nth-of-type(2) .flex-center .cl p {
	font-size: 16px;
	text-align: left;
	width: 100%;
}
.caseta-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.caseta-22 ul li:before {
	background-color: #0099d8 !important;
}
.caseta-22 .fp-footer {
	background: #0099d8;
}
.caseta-22 .fp-footer:after {
	border-left: 60px solid #0099d8;
}
.caseta-22 .fp-footer .fp-btn {
	color: #fff;
	background: #404040;
	border: none;
	text-transform: none;
}
.caseta-22 .fp-hero {
	-ms-flex-align: stretch;
	align-items: stretch;
}
.caseta-22 .fp-hero .col h1 {
	max-width: 400px;
	color: #0099d8 !important;
	font-size: 56px !important;
	text-align: left;
	font-weight: 100 !important;
	display: block;
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.caseta-22 .fp-hero .col p {
	color: #000 !important;
	font-size: 18px;
	text-align: left;
}
.caseta-22 .fp-hero .col:first-of-type {
	padding: 50px 50px 100px;
}
@media (max-width: 960px) {
	.caseta-22 .fp-hero .col:first-of-type {
		padding: 30px;
	}
}
.caseta-22 .fp-hero .col:first-of-type img {
	max-width: 300px;
	height: auto;
}
.caseta-22 .fp-hero .col:nth-of-type(2) {
	background-size: cover;
	background-position: 100%;
}
@media (max-width: 960px) {
	.caseta-22 .fp-hero .col:nth-of-type(2) {
		min-height: 500px;
	}
}
.caseta-22 .video-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.caseta-22 .video-section .col {
	border: 2px solid #fff;
	width: 400px;
	height: auto;
	margin-top: -100px;
}
@media (max-width: 960px) {
	.caseta-22 .video-section .col {
		width: 95%;
		max-width: 400px;
	}
}
.caseta-22 .section-03 {
	background-color: #f2f2f2;
	padding: 130px 0 0 50px;
	margin: -160px 0 50px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.caseta-22 .section-03 {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
		padding-top: 170px;
		padding-left: 0;
	}
}
.caseta-22 .section-03 .flex-center {
	width: calc(100% - 200px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 30px;
}
@media (max-width: 960px) {
	.caseta-22 .section-03 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		-ms-flex-align: center;
		align-items: center;
	}
}
.caseta-22 .section-03 .flex-center .col {
	width: 22%;
	height: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.caseta-22 .section-03 .flex-center .col {
		-ms-flex-direction: column;
		flex-direction: column;
		width: 90%;
		max-width: 400px;
	}
	.caseta-22 .section-03 .flex-center .col:not(:last-of-type) {
		margin-bottom: 30px;
	}
}
.caseta-22 .section-03 .flex-center .col img {
	margin-bottom: 20px;
}
.caseta-22 .section-03 .flex-center .col p {
	text-align: center;
	font-size: 12px;
}
@media (max-width: 960px) {
	.caseta-22 .section-03 .flex-center .col p {
		font-size: 14px;
	}
}
.caseta-22 .section-03 .product {
	width: 200px;
	height: auto;
}
@media (max-width: 960px) {
	.caseta-22 .section-03 .product {
		-ms-flex-item-align: end;
		align-self: flex-end;
	}
}
.sunnata-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.sunnata-22 ul li:before {
	background-color: #c0571c !important;
}
.sunnata-22 .fp-footer {
	background: #c0571c;
}
.sunnata-22 .fp-footer:after {
	border-left: 60px solid #c0571c;
}
.sunnata-22 .fp-footer .fp-btn {
	color: #fff;
	background: #404040;
	border: none;
	text-transform: none;
	border-radius: 10px;
}
.sunnata-22 .fp-hero {
	position: relative;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.sunnata-22 .fp-hero {
		background-position: 50%;
	}
}
.sunnata-22 .fp-hero .product {
	width: 200px;
	height: auto;
	margin: 10px 30px;
	position: relative;
	z-index: 5;
}
@media (max-width: 960px) {
	.sunnata-22 .fp-hero .product {
		margin-bottom: 130px;
		width: 30%;
		height: auto;
	}
}
.sunnata-22 .fp-hero h1 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 10px 50px 10px 260px;
	margin: 0;
	color: #fff !important;
	font-size: 42px !important;
	text-align: left;
	font-weight: 100 !important;
	display: block;
	z-index: 4;
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
	background: linear-gradient(-90deg, rgba(49, 80, 81, 0), #315051 80%, #315051);
}
@media (max-width: 960px) {
	.sunnata-22 .fp-hero h1 {
		padding: 20px 30px;
		line-height: 0.7 !important;
	}
}
.sunnata-22 .fp-hero h1 span {
	font-weight: 700;
	font-size: 0.5em;
	line-height: 0;
}
.sunnata-22 .section-02 {
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 0 50px;
}
@media (max-width: 960px) {
	.sunnata-22 .section-02 {
		padding: 0 30px;
	}
}
.sunnata-22 .section-02 h2 {
	text-transform: inherit !important;
	color: #c0571c !important;
	font-size: 24px !important;
}
.sunnata-22 .video-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 50px;
	margin-bottom: -20px;
}
@media (max-width: 960px) {
	.sunnata-22 .video-section {
		-ms-flex-direction: column;
		flex-direction: column;
		margin-bottom: 0;
		padding: 0 30px;
	}
}
@media (max-width: 960px) {
	.sunnata-22 .video-section .col:first-of-type {
		width: 100%;
	}
}
.sunnata-22 .video-section .col:nth-of-type(2) {
	padding: 0 40px;
}
@media (max-width: 960px) {
	.sunnata-22 .video-section .col:nth-of-type(2) {
		padding: 30px 0;
	}
}
.sunnata-22 .section-03 {
	background-color: #315051;
	padding: 80px 50px 0;
	margin-bottom: 50px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.sunnata-22 .section-03 {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
		padding: 0;
	}
}
.sunnata-22 .section-03 .flex-center {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 30px;
}
@media (max-width: 960px) {
	.sunnata-22 .section-03 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		padding: 30px;
	}
}
.sunnata-22 .section-03 .flex-center .col {
	width: 20%;
	height: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.sunnata-22 .section-03 .flex-center .col {
		-ms-flex-direction: column;
		flex-direction: column;
		width: 90%;
		max-width: 400px;
	}
	.sunnata-22 .section-03 .flex-center .col:not(:last-of-type) {
		margin-bottom: 30px;
	}
}
.sunnata-22 .section-03 .flex-center .col img {
	margin-bottom: 20px;
}
.sunnata-22 .section-03 .flex-center .col p {
	color: #fff !important;
	width: 100%;
	font-size: 18px;
}
.sunnata-22 .section-03 .flex-center .col p strong {
	color: #c0571c;
}
@media (max-width: 960px) {
	.sunnata-22 .section-03 .flex-center .col p {
		font-size: 14px;
	}
}
.maestro-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.maestro-22 ul li:before {
	background-color: #c0571c !important;
}
.maestro-22 .fp-footer {
	background: #0099d8;
}
.maestro-22 .fp-footer:after {
	border-left: 60px solid #0099d8;
}
.maestro-22 .fp-footer .fp-btn {
	color: #fff;
	background: #000;
	border: none;
	text-transform: none;
	border-radius: 10px;
}
.maestro-22 .fp-hero {
	position: relative;
	-ms-flex-direction: column;
	flex-direction: column;
}
.maestro-22 .fp-hero img {
	width: 100%;
}
.maestro-22 .fp-hero h1 {
	width: 100%;
	color: #0099d8 !important;
	font-size: 48px !important;
	text-align: center;
	font-weight: 100 !important;
	display: block;
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
	margin-top: -70px;
	margin-bottom: 0;
}
@media (max-width: 960px) {
	.maestro-22 .fp-hero h1 {
		margin-top: 0;
	}
}
.maestro-22 .fp-hero p {
	color: #000 !important;
	font-size: 18px;
	text-align: center;
	margin-bottom: 30px;
}
.maestro-22 .fp-hero h1,
.maestro-22 .fp-hero p {
	padding-left: 100px;
}
@media (max-width: 960px) {
	.maestro-22 .fp-hero h1,
	.maestro-22 .fp-hero p {
		padding-left: 0;
	}
}
.maestro-22 .video-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 30px;
	margin-bottom: 50px;
}
@media (max-width: 960px) {
	.maestro-22 .video-section {
		-ms-flex-direction: column;
		flex-direction: column;
		margin-bottom: 0;
		padding: 0 30px;
	}
}
.maestro-22 .video-section .col {
	width: 60%;
}
.maestro-22 .video-section .col:first-of-type {
	padding: 0 40px;
}
.maestro-22 .video-section .col:first-of-type p {
	font-size: 18px;
}
@media (max-width: 960px) {
	.maestro-22 .video-section .col:first-of-type {
		width: 100%;
		padding: 0 0 30px;
	}
}
.maestro-22 .video-section .col:nth-of-type(2) {
	width: 40%;
}
@media (max-width: 960px) {
	.maestro-22 .video-section .col:nth-of-type(2) {
		width: 100%;
	}
}
.maestro-22 .section-03 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.maestro-22 .section-03 h2 {
	background-color: #315051;
	color: #fff !important;
	font-weight: 100 !important;
	text-align: center;
	text-transform: inherit !important;
	background: linear-gradient(90deg, #476312, #88b237);
	padding: 10px 30px;
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.maestro-22 .section-03 .flex-center {
	width: 90%;
	margin: 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.maestro-22 .section-03 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
		padding: 30px;
	}
}
.maestro-22 .section-03 .flex-center .col {
	width: 20%;
	height: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.maestro-22 .section-03 .flex-center .col {
		-ms-flex-direction: column;
		flex-direction: column;
		width: 90%;
		max-width: 400px;
	}
	.maestro-22 .section-03 .flex-center .col:not(:last-of-type) {
		margin-bottom: 30px;
	}
}
.maestro-22 .section-03 .flex-center .col img {
	margin-bottom: 20px;
	width: 100%;
	height: auto;
}
.maestro-22 .section-03 .flex-center .col p {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	font-size: 18px;
	margin: 0;
}
.maestro-22 .section-03 .flex-center .col p img {
	width: 30px;
	height: auto;
	margin-right: 10px;
}
@media (max-width: 960px) {
	.maestro-22 .section-03 .flex-center .col p {
		font-size: 14px;
	}
}
.maestro-22 .section-03 p {
	width: 85%;
	margin: 0 auto 50px;
}
.acuity-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.acuity-22 ul li:before {
	background-color: #e5332e !important;
}
.acuity-22 .fp-footer {
	background: #0e3266;
}
.acuity-22 .fp-footer:after {
	border-left: 60px solid #0e3266;
}
.acuity-22 .fp-footer .fp-btn {
	color: #fff;
	background: #e5332e;
	border: none;
	text-transform: none;
	border-radius: 10px;
}
.acuity-22 .fp-hero {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	margin-bottom: 30px;
}
@media (max-width: 960px) {
	.acuity-22 .fp-hero {
		margin-bottom: 0;
	}
}
.acuity-22 .fp-hero h1 {
	margin: 0;
	color: #fff !important;
	font-size: 42px !important;
	line-height: 1;
	text-align: left;
	font-weight: 700 !important;
	display: block;
	width: 100%;
	z-index: 4;
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 10px 5%;
	background-color: rgba(0, 0, 0, 0.75);
}
.acuity-22 .fp-hero .col {
	position: relative;
}
.acuity-22 .fp-hero .col:first-of-type {
	background-position: 100% 100%;
	background-size: cover;
	width: calc(100% - 150px);
	height: 300px;
	z-index: 1;
}
@media (max-width: 960px) {
	.acuity-22 .fp-hero .col:first-of-type {
		width: 100%;
		min-height: 300px;
	}
}
.acuity-22 .fp-hero .col:nth-of-type(2) {
	width: 150px;
	margin-left: -150px;
	z-index: 4;
	display: block;
	position: relative;
}
@media (max-width: 960px) {
	.acuity-22 .fp-hero .col:nth-of-type(2) {
		width: 100%;
		margin-left: 0;
		margin: 0 auto;
	}
}
.acuity-22 .fp-hero .col:nth-of-type(2) .triangle {
	position: absolute;
	top: 0;
	left: 0;
	width: 200%;
	height: auto;
	-ms-flex: none;
	flex: none;
	z-index: 0;
}
@media (max-width: 960px) {
	.acuity-22 .fp-hero .col:nth-of-type(2) .triangle {
		display: none;
	}
}
.acuity-22 .fp-hero .col:nth-of-type(2) .logo {
	z-index: 2;
	position: relative;
	margin: 64px auto;
	transform: translate(76px);
	width: 130px;
	height: auto;
}
@media (max-width: 960px) {
	.acuity-22 .fp-hero .col:nth-of-type(2) .logo {
		transform: none;
		display: block;
		width: 175px;
		margin: 30px auto;
	}
}
.acuity-22 h2 {
	background-color: #e5332e;
	color: #fff !important;
	text-transform: inherit !important;
	font-weight: 400 !important;
	font-size: 30px !important;
	width: 100%;
	padding: 5px 5%;
}
.acuity-22 .video-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 5%;
}
@media (max-width: 960px) {
	.acuity-22 .video-section {
		-ms-flex-direction: column;
		flex-direction: column;
		margin-bottom: 0;
		padding: 0 30px;
	}
}
.acuity-22 .video-section .col:first-of-type {
	width: 55%;
}
@media (max-width: 960px) {
	.acuity-22 .video-section .col:first-of-type {
		width: 100%;
	}
}
.acuity-22 .video-section .col:first-of-type .video-preview {
	margin-top: 30px;
	border: 1px solid grey;
}
.acuity-22 .video-section .col:nth-of-type(2) {
	width: 45%;
	padding-left: 30px;
	position: relative;
	z-index: 4;
}
@media (max-width: 960px) {
	.acuity-22 .video-section .col:nth-of-type(2) {
		padding: 30px 0;
		width: 100%;
	}
}
.acuity-22 .video-section .col:nth-of-type(2) img {
	width: 90%;
	margin: -100px auto 30px;
}
@media (max-width: 960px) {
	.acuity-22 .video-section .col:nth-of-type(2) img {
		margin: 30px auto;
		max-width: 300px;
		display: block;
	}
}
.acuity-22 .video-section .col:nth-of-type(2) p {
	font-size: 16px;
}
.acuity-22 .video-section.first .col:nth-of-type(2) img {
	margin-top: -170px;
}
@media (max-width: 960px) {
	.acuity-22 .video-section.first .col:nth-of-type(2) img {
		margin-top: 0;
	}
}
.acuity-22 .bullets {
	width: 90%;
	margin: 20px auto;
}
@media (max-width: 960px) {
	.acuity-22 .bullets {
		margin-top: 0;
	}
}
.acuity-22 .bullets ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.acuity-22 .bullets ul {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.acuity-22 .bullets ul li {
	width: 30%;
	padding-right: 10px;
}
@media (max-width: 960px) {
	.acuity-22 .bullets ul li {
		width: 100%;
	}
}
.acuity-22 .section-03 .flex-center {
	width: 90%;
	margin: 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid grey;
}
@media (max-width: 960px) {
	.acuity-22 .section-03 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.acuity-22 .section-03 .flex-center img:first-of-type {
	width: 30%;
}
@media (max-width: 960px) {
	.acuity-22 .section-03 .flex-center img:first-of-type {
		width: 100%;
	}
}
.acuity-22 .section-03 .flex-center img:nth-of-type(2) {
	width: 70%;
}
@media (max-width: 960px) {
	.acuity-22 .section-03 .flex-center img:nth-of-type(2) {
		width: 100%;
	}
}
.acuity-22 ul.homepage-slider {
	border: none;
	padding: none;
	-ms-flex-align: stretch;
	align-items: stretch;
	display: -ms-flexbox;
	display: flex;
}
.acuity-22 ul.homepage-slider li:before {
	display: none;
}
.acuity-22 ul.homepage-slider li {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	margin: 0 !important;
}
.acuity-22 ul.homepage-slider img {
	width: auto;
	height: 500px;
	display: block;
	margin: 30px auto;
}
@media (max-width: 960px) {
	.acuity-22 ul.homepage-slider img {
		width: 100%;
		height: auto;
		margin: 0;
	}
}
.acuity-22 p.red {
	color: #e5332e !important;
	font-size: 18px !important;
}
.acuity-22 .bx-wrapper {
	width: 90%;
	margin: 30px auto 50px;
	display: block;
	border: 1px solid grey;
}
.acuity-22 .bx-pager {
	display: none !important;
}
.acuity-22 .bx-viewport {
	border: none !important;
	left: 0 !important;
	box-shadow: none !important;
}
.acuity-22.hgx h1 {
	font-weight: 100 !important;
}
.acuity-22.hgx h1 strong {
	font-weight: 700 !important;
}
.acuity-22.hgx h2 {
	background: none !important;
	color: #e5332e !important;
	padding: 0;
	font-size: 28px !important;
}
.acuity-22.hgx .video-section.first .col:nth-of-type(2) img {
	margin-top: 0;
}
.acuity-22.hgx .video-section ul li {
	font-weight: 700;
}
.acuity-22.hgx .video-section ul li:before {
	border-radius: 0;
}
.acuity-22.hgx .video-section .col:first-of-type {
	width: 65%;
}
@media (max-width: 960px) {
	.acuity-22.hgx .video-section .col:first-of-type {
		width: 100%;
	}
}
.acuity-22.hgx .video-section .col:nth-of-type(2) {
	width: 35%;
}
@media (max-width: 960px) {
	.acuity-22.hgx .video-section .col:nth-of-type(2) {
		width: 100%;
	}
}
.acuity-22.hgx .four-col ul {
	width: 90%;
	margin: 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.acuity-22.hgx .four-col ul {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
.acuity-22.hgx .four-col ul li {
	width: 23%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 0;
}
@media (max-width: 960px) {
	.acuity-22.hgx .four-col ul li {
		width: 100%;
		max-width: 300px;
		margin-bottom: 30px;
	}
}
.acuity-22.hgx .four-col ul li:before {
	display: none;
}
.acuity-22.hgx .four-col ul li p {
	font-size: 14px;
}
.downlight-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.downlight-22 ul li:before {
	background-color: #f7911d !important;
}
.downlight-22 .fp-footer {
	background: #485865;
}
.downlight-22 .fp-footer:after {
	border-left: 60px solid #485865;
}
.downlight-22 .fp-footer .fp-btn {
	color: #fff;
	background: #f7911d;
	border: none;
	text-transform: none;
	border-radius: 10px;
}
.downlight-22 .fp-hero {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #f7911d;
	padding-bottom: 30px;
}
.downlight-22 .fp-hero h1 {
	margin: 0;
	color: #f7911d !important;
	font-size: 42px !important;
	text-align: center;
	font-weight: 100 !important;
	display: block;
	z-index: 4;
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.downlight-22 .fp-hero .col:first-of-type {
	background-position: 100% 100%;
	background-size: cover;
	width: 70%;
}
@media (max-width: 960px) {
	.downlight-22 .fp-hero .col:first-of-type {
		width: 95%;
		min-height: 300px;
	}
}
.downlight-22 .fp-hero .col:nth-of-type(2) {
	width: 30%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 50px 30px;
}
@media (max-width: 960px) {
	.downlight-22 .fp-hero .col:nth-of-type(2) {
		width: 100%;
	}
}
.downlight-22 .fp-hero .col:nth-of-type(2) img {
	-ms-flex: none;
	flex: none;
	width: inherit;
}
@media (max-width: 960px) {
	.downlight-22 .fp-hero .col:nth-of-type(2) img {
		max-width: 200px;
	}
}
.downlight-22 .fp-hero .col:nth-of-type(2) img.energy {
	max-width: 100px;
}
.downlight-22 .fp-hero .col:nth-of-type(2) h1 {
	margin: 20px auto;
}
.downlight-22 .video-section {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 5%;
}
@media (max-width: 960px) {
	.downlight-22 .video-section {
		-ms-flex-direction: column;
		flex-direction: column;
		margin-bottom: 0;
		padding: 0 30px;
	}
}
.downlight-22 .video-section .col:first-of-type {
	width: 55%;
}
@media (max-width: 960px) {
	.downlight-22 .video-section .col:first-of-type {
		width: 100%;
	}
}
.downlight-22 .video-section .col:first-of-type h2 {
	color: #f7911d !important;
	text-transform: inherit !important;
	font-weight: 400 !important;
	font-size: 30px !important;
}
.downlight-22 .video-section .col:first-of-type .video-preview {
	border: 1px solid grey;
	margin-top: 30px;
}
.downlight-22 .video-section .col:nth-of-type(2) {
	width: 45%;
	padding-left: 30px;
	position: relative;
	z-index: 4;
}
@media (max-width: 960px) {
	.downlight-22 .video-section .col:nth-of-type(2) {
		padding: 30px 0;
		width: 100%;
	}
}
.downlight-22 .video-section .col:nth-of-type(2) img {
	width: 90%;
	margin: -100px auto 30px;
}
@media (max-width: 960px) {
	.downlight-22 .video-section .col:nth-of-type(2) img {
		margin: 30px auto;
		max-width: 300px;
		display: block;
	}
}
.downlight-22 .video-section .col:nth-of-type(2) p {
	font-size: 16px;
}
.downlight-22 .bullets {
	width: 90%;
	margin: 20px auto;
}
@media (max-width: 960px) {
	.downlight-22 .bullets {
		margin-top: 0;
	}
}
.downlight-22 .bullets ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.downlight-22 .bullets ul {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.downlight-22 .bullets ul li {
	width: 25%;
	padding-right: 10px;
}
@media (max-width: 960px) {
	.downlight-22 .bullets ul li {
		width: 100%;
	}
}
.downlight-22 .bullets ul li:nth-of-type(3) {
	width: 50%;
}
@media (max-width: 960px) {
	.downlight-22 .bullets ul li:nth-of-type(3) {
		width: 100%;
	}
}
.downlight-22 .section-03 .flex-center {
	width: 90%;
	margin: 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid grey;
}
@media (max-width: 960px) {
	.downlight-22 .section-03 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.downlight-22 .section-03 .flex-center img:first-of-type {
	width: 30%;
}
@media (max-width: 960px) {
	.downlight-22 .section-03 .flex-center img:first-of-type {
		width: 100%;
	}
}
.downlight-22 .section-03 .flex-center img:nth-of-type(2) {
	width: 70%;
}
@media (max-width: 960px) {
	.downlight-22 .section-03 .flex-center img:nth-of-type(2) {
		width: 100%;
	}
}
.downlight-22 .video-section-02 {
	margin-top: 100px;
	border-top: 1px solid #f7911d;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 5%;
}
@media (max-width: 960px) {
	.downlight-22 .video-section-02 {
		-ms-flex-direction: column;
		flex-direction: column;
		margin-bottom: 0;
		padding: 0 30px;
	}
}
.downlight-22 .video-section-02 .col:first-of-type {
	width: 55%;
}
@media (max-width: 960px) {
	.downlight-22 .video-section-02 .col:first-of-type {
		width: 100%;
	}
}
.downlight-22 .video-section-02 .col:first-of-type h2 {
	color: #f7911d !important;
	text-transform: inherit !important;
	font-weight: 400 !important;
	font-size: 30px !important;
}
.downlight-22 .video-section-02 .col:first-of-type .video-preview {
	border: 1px solid grey;
	margin: 30px 0;
}
.downlight-22 .video-section-02 .col:first-of-type p:not(.sub) {
	font-size: 16px;
}
.downlight-22 .video-section-02 .col:nth-of-type(2) {
	width: 45%;
	padding-left: 30px;
	position: relative;
	z-index: 4;
}
@media (max-width: 960px) {
	.downlight-22 .video-section-02 .col:nth-of-type(2) {
		padding: 30px 0;
		width: 80%;
	}
}
.downlight-22 .video-section-02 .col:nth-of-type(2) .product {
	width: 90%;
	margin: -30px auto 30px;
}
.downlight-22 .video-section-02 .col:nth-of-type(2) .sizes {
	width: 80%;
	height: auto;
}
.downlight-22 .video-section-02 .col:nth-of-type(2) p {
	font-size: 16px;
}
.nvent-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.nvent-22 ul li:before {
	background-color: #c4262e !important;
}
.nvent-22 .fp-footer {
	background: #c4262e;
}
.nvent-22 .fp-footer:after {
	border-left: 60px solid #c4262e;
}
.nvent-22 .fp-footer .fp-btn {
	color: #fff;
	background: #c4262e;
	border: 2px solid #fff;
	text-transform: none;
}
.nvent-22 .fp-hero,
.nvent-22 .fp-hero .col {
	display: -ms-flexbox;
	display: flex;
}
.nvent-22 .fp-hero .col {
	width: 100%;
	padding: 20px 50px;
	min-height: 200px;
	-ms-flex-align: end;
	align-items: flex-end;
}
@media (max-width: 960px) {
	.nvent-22 .fp-hero .col {
		padding: 20px;
	}
}
.nvent-22 .fp-hero .col h1 {
	color: #fff !important;
	font-size: 36px !important;
	font-weight: 700;
}
.nvent-22 .fp-section.first {
	padding: 20px 50px;
}
@media (max-width: 960px) {
	.nvent-22 .fp-section.first {
		padding: 20px;
	}
}
.nvent-22 .fp-section.first h2 {
	text-transform: inherit !important;
	color: #c4262e !important;
	font-size: 24px !important;
}
.nvent-22 .fp-section.first p {
	font-size: 18px;
}
.nvent-22 .fp-section.first ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 20px;
}
@media (max-width: 960px) {
	.nvent-22 .fp-section.first ul {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.nvent-22 .fp-section.first ul li {
	width: 30%;
	font-weight: 700;
}
@media (max-width: 960px) {
	.nvent-22 .fp-section.first ul li {
		width: 100%;
	}
}
.nvent-22 .slider-section {
	-ms-flex-direction: column;
	flex-direction: column;
	border-top: 1px solid #000;
	width: calc(100% - 100px);
	margin: 0 auto 60px;
}
@media (max-width: 960px) {
	.nvent-22 .slider-section {
		width: 100%;
	}
}
.nvent-22 .slider-section h2 {
	color: #000 !important;
	font-size: 24px !important;
}
@media (max-width: 960px) {
	.nvent-22 .slider-section h2 {
		padding: 0 10px;
	}
}
.nvent-22 .bx-viewport {
	box-shadow: none !important;
	left: 0 !important;
	border: none !important;
}
.nvent-22 .bx-pager {
	display: none;
}
.nvent-22 .bx-controls-direction a {
	background: none;
	width: 50px !important;
	height: 50px !important;
}
@media (max-width: 960px) {
	.nvent-22 .bx-controls-direction a {
		top: auto !important;
		bottom: -45px;
	}
}
.nvent-22 .bx-controls-direction a:before {
	content: "";
	width: 50px;
	height: 50px;
	background-image: url(../images/2022/june/imgs/slider-arrow.png);
	background-size: 100% auto;
	background-repeat: no-repeat;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}
.nvent-22 .bx-controls-direction a.bx-next:before {
	transform: rotate(180deg);
}
.nvent-22 ul.homepage-slider {
	border: none;
	padding: none;
	-ms-flex-align: stretch;
	align-items: stretch;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.nvent-22 ul.homepage-slider li {
	padding: 0 70px;
}
@media (max-width: 960px) {
	.nvent-22 ul.homepage-slider li {
		padding: 0 10px;
	}
}
.nvent-22 ul.homepage-slider li:before {
	display: none;
}
.nvent-22 ul.homepage-slider li .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	border: 1px solid #000;
}
@media (max-width: 960px) {
	.nvent-22 ul.homepage-slider li .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.nvent-22 ul.homepage-slider li .flex-center .col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.nvent-22 ul.homepage-slider li .flex-center .col:first-of-type {
	width: 55%;
	position: relative;
	padding: 20px;
}
@media (max-width: 960px) {
	.nvent-22 ul.homepage-slider li .flex-center .col:first-of-type {
		width: 100%;
	}
}
.nvent-22 ul.homepage-slider li .flex-center .col:first-of-type img {
	height: 250px;
	width: auto;
}
.nvent-22 ul.homepage-slider li .flex-center .col:first-of-type .video-link {
	position: absolute;
	bottom: 10px;
	right: 10px;
	color: #c4262e;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 700;
}
.nvent-22 ul.homepage-slider li .flex-center .col:first-of-type .video-link img {
	margin-left: 5px;
	width: 35px !important;
	height: auto;
}
.nvent-22 ul.homepage-slider li .flex-center .col:nth-of-type(2) {
	width: 45%;
	padding: 20px;
	background: #ffeb56;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
}
@media (max-width: 960px) {
	.nvent-22 ul.homepage-slider li .flex-center .col:nth-of-type(2) {
		width: 100%;
	}
}
.nvent-22 ul.homepage-slider li .flex-center .col:nth-of-type(2) p.title {
	color: #c4262e !important;
	text-align: left;
	font-weight: 700;
	font-size: 18px;
}
.nvent-22 ul.homepage-slider li .flex-center .col:nth-of-type(2) ul li {
	padding: 0 0 0 20px;
}
.nvent-22 ul.homepage-slider li .flex-center .col:nth-of-type(2) ul li:before {
	content: "";
	width: 5px;
	height: 5px;
	background-color: #c4262e;
	border-radius: 50%;
	position: absolute;
	display: block;
	top: 0.5em;
	left: 0;
}
.nvent-22.overhead .fp-hero {
	background: #1b1719;
	display: -ms-flexbox;
	display: flex;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.nvent-22.overhead .fp-hero {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.nvent-22.overhead .fp-hero .col {
	background-size: cover;
	background-position: 50%;
	min-height: 275px;
	position: relative;
}
.nvent-22.overhead .fp-hero .col:before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}
.nvent-22.overhead .fp-hero .col h1 {
	z-index: 4;
}
@media (max-width: 960px) {
	.nvent-22.overhead .fp-section.first {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.nvent-22.overhead .fp-section.first .col:first-of-type {
	width: 65%;
	padding-right: 30px;
}
@media (max-width: 960px) {
	.nvent-22.overhead .fp-section.first .col:first-of-type {
		width: 100%;
		padding-right: 0;
	}
}
.nvent-22.overhead .fp-section.first .col:nth-of-type(2) {
	width: 35%;
}
@media (max-width: 960px) {
	.nvent-22.overhead .fp-section.first .col:nth-of-type(2) {
		width: 100%;
	}
}
.nvent-22.overhead .fp-section.first .col:nth-of-type(2) img {
	width: 100%;
}
.nvent-22.overhead .fp-section.first .red {
	font-size: 18px !important;
	color: #c4262e !important;
	font-weight: 700;
}
.nvent-22.overhead .fp-section.first .red.underline {
	text-decoration: underline;
	margin-bottom: 0;
}
.nvent-22.overhead .fp-section.first ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 20px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 auto;
	-ms-flex-direction: column;
	flex-direction: column;
}
.nvent-22.overhead .fp-section.first ul li {
	width: 100%;
	padding-right: 10px;
	font-weight: 400 !important;
}
@media (max-width: 960px) {
	.nvent-22.overhead .fp-section.first ul li {
		width: 100%;
	}
}
.nvent-22.overhead ul.homepage-slider li .flex-center .col:first-of-type img {
	width: 100%;
	height: auto;
}
.nvent-22.pre-fab .fp-hero .col {
	background-size: cover;
	background-position: 50%;
	min-height: 275px;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.first {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.nvent-22.pre-fab .fp-section.first .col:first-of-type {
	width: 100%;
}
.nvent-22.pre-fab .fp-section.first .col:first-of-type h2 {
	color: #000 !important;
	margin-top: 0 !important;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.first .col:first-of-type {
		width: 100%;
		padding: 0;
	}
}
.nvent-22.pre-fab .fp-section.prefab {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
.nvent-22.pre-fab .fp-section.prefab h2 {
	margin: 0 !important;
	padding: 10px 50px !important;
	background-color: #c4262e !important;
	color: #fff !important;
	width: 100%;
	font-size: 18px !important;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.prefab h2 {
		padding: 10px 20px !important;
	}
}
.nvent-22.pre-fab .fp-section.prefab .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 30px 50px;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.prefab .flex-center {
		padding: 20px;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.nvent-22.pre-fab .fp-section.prefab .flex-center .col:first-of-type {
	width: 30%;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.prefab .flex-center .col:first-of-type {
		width: 100%;
	}
}
.nvent-22.pre-fab .fp-section.prefab .flex-center .col:first-of-type img {
	width: 100%;
	height: auto;
}
.nvent-22.pre-fab .fp-section.prefab .flex-center .col:nth-of-type(2) {
	width: 70%;
	padding-left: 30px;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.prefab .flex-center .col:nth-of-type(2) {
		width: 100%;
		padding: 0;
		margin-top: 20px;
	}
}
.nvent-22.pre-fab .fp-section.prefab .flex-center.invert {
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.prefab .flex-center.invert {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.nvent-22.pre-fab .fp-section.prefab .flex-center.invert .col:nth-of-type(2) {
	padding-left: 0;
	padding-right: 30px;
}
@media (max-width: 960px) {
	.nvent-22.pre-fab .fp-section.prefab .flex-center.invert .col:nth-of-type(2) {
		padding-right: 0;
	}
}
.nvent-22.pre-fab .fp-section.prefab .flex-center p {
	color: #000 !important;
	font-size: 16px !important;
}
.nvent-22.pre-fab .fp-section.prefab .flex-center p .red {
	color: #c4262e !important;
}
.nvent-22.pre-fab .fp-section.prefab .flex-center ul {
	margin-top: 15px;
}
.nvent-22.pre-fab .fp-section.prefab .flex-center ul li {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 0;
	font-size: 16px !important;
	margin-bottom: 20px;
}
.nvent-22.pre-fab .fp-section.prefab .flex-center ul li:before {
	content: "";
	background-image: url(../images/2022/june/imgs/checkmark.jpg) !important;
	background-size: 100% auto;
	background-repeat: no-repeat;
	width: 25px;
	height: 25px;
	position: relative;
	margin-right: 10px;
	top: auto;
	left: auto;
	-ms-flex: none;
	flex: none;
}
.nvent-22.pre-fab ul.homepage-slider li .flex-center .col:first-of-type {
	-ms-flex-direction: column;
	flex-direction: column;
}
.abb-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.abb-22 .fp-footer {
	background: #ff000f;
}
.abb-22 .fp-footer:after {
	border-left: 60px solid #ff000f;
}
.abb-22 .fp-footer .fp-btn {
	color: #fff;
	background: #6e6e6e;
	border: 2px solid #6e6e6e;
	text-transform: none;
}
.abb-22 .fp-hero {
	margin-bottom: 0;
	-ms-flex-align: stretch;
	align-items: stretch;
}
@media (max-width: 960px) {
	.abb-22 .fp-hero {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.abb-22 .fp-hero .col {
	min-height: 500px;
}
.abb-22 .fp-hero .col:first-of-type {
	width: 40%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 50px 75px;
}
@media (max-width: 960px) {
	.abb-22 .fp-hero .col:first-of-type {
		width: 100%;
		padding: 50px 30px;
		min-height: inherit;
	}
}
.abb-22 .fp-hero .col:first-of-type h1 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
	color: #000 !important;
	font-size: 36px !important;
	font-weight: 100 !important;
}
.abb-22 .fp-hero .col:first-of-type a {
	background-color: #6e6e6e;
	padding: 12px 10px;
	color: #fff;
	font-size: 18px;
}
.abb-22 .fp-hero .col:nth-of-type(2) {
	width: 60%;
}
@media (max-width: 960px) {
	.abb-22 .fp-hero .col:nth-of-type(2) {
		width: 100%;
	}
}
.abb-22 .fp-section.first {
	background-image: url(../images/2022/june/imgs/abb-bg.jpg);
	padding: 30px 50px;
}
.abb-22 .fp-section.first h2,
.abb-22 .fp-section.first p {
	color: #fff !important;
}
.abb-22 .fp-section.first h2 {
	text-transform: inherit !important;
	font-weight: 600 !important;
	font-size: 28px !important;
	position: relative;
}
.abb-22 .fp-section.first h2:before {
	content: "";
	width: 30px;
	height: 3px;
	position: absolute;
	top: -15px;
	left: 0;
	background-color: #fff;
}
.abb-22 .fp-section.solution {
	-ms-flex-direction: column;
	flex-direction: column;
}
.abb-22 .fp-section.solution h2 {
	background: #ededed;
	padding: 10px 50px;
	font-size: 20px !important;
	color: #000 !important;
	text-transform: inherit !important;
}
.abb-22 .fp-section.solution .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 30px 50px;
}
@media (max-width: 960px) {
	.abb-22 .fp-section.solution .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		margin: 30px;
	}
}
.abb-22 .fp-section.solution .flex-center .col:first-of-type {
	width: 40%;
}
@media (max-width: 960px) {
	.abb-22 .fp-section.solution .flex-center .col:first-of-type {
		width: 100%;
	}
}
.abb-22 .fp-section.solution .flex-center .col:first-of-type img {
	width: 100%;
	height: auto;
}
.abb-22 .fp-section.solution .flex-center .col:nth-of-type(2) {
	width: 60%;
	padding: 0 0 0 30px;
}
@media (max-width: 960px) {
	.abb-22 .fp-section.solution .flex-center .col:nth-of-type(2) {
		width: 100%;
		padding: 30px 0 0;
	}
}
.abb-22 .fp-section.solution .flex-center .col:nth-of-type(2) p {
	font-size: 16px;
}
.abb-22 .fp-section.solution .flex-center .col:nth-of-type(2) p:first-of-type {
	margin-bottom: 20px;
}
.abb-22 .fp-section.solution .flex-center .col:nth-of-type(2) img {
	width: 100%;
	height: auto;
	margin-left: -10px;
}
@media (max-width: 960px) {
	.abb-22 .fp-section.solution .flex-center .col:nth-of-type(2) img {
		margin-left: 0;
	}
}
.ledvance-22 .fp-hero img {
	width: 100%;
	height: auto;
}
.ledvance-22 .fp-footer {
	background: #666;
	margin-top: 50px;
}
.ledvance-22 .fp-footer:after {
	border-left: 60px solid #666;
}
.ledvance-22 .fp-footer .fp-btn {
	color: #fff;
	background: #666;
	border: 2px solid #f60;
	text-transform: none;
}
.ledvance-22 .fp-section.first {
	-ms-flex-direction: column;
	flex-direction: column;
}
.ledvance-22 .fp-section.first h2 {
	color: #f60 !important;
	font-weight: 100 !important;
	text-align: center;
	margin-bottom: 50px;
	text-transform: inherit !important;
}
@media (max-width: 960px) {
	.ledvance-22 .fp-section.first h2 {
		margin-bottom: 25px;
	}
}
.ledvance-22 .fp-section.first .flex-center {
	padding: 0 50px 0 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
}
@media (max-width: 960px) {
	.ledvance-22 .fp-section.first .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0;
	}
}
.ledvance-22 .fp-section.first .flex-center .col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
}
.ledvance-22 .fp-section.first .flex-center .col:first-of-type {
	width: 60%;
}
@media (max-width: 960px) {
	.ledvance-22 .fp-section.first .flex-center .col:first-of-type {
		width: 100%;
	}
}
.ledvance-22 .fp-section.first .flex-center .col:first-of-type p {
	padding: 0 10px 20px 50px;
}
@media (max-width: 960px) {
	.ledvance-22 .fp-section.first .flex-center .col:first-of-type p {
		padding: 0 20px 20px;
	}
}
.ledvance-22 .fp-section.first .flex-center .col:nth-of-type(2) {
	width: 40%;
	border-left: 1px solid #f60;
	padding-left: 30px;
}
@media (max-width: 960px) {
	.ledvance-22 .fp-section.first .flex-center .col:nth-of-type(2) {
		width: 100%;
		border-left: none;
		padding: 0 20px;
	}
}
.ledvance-22 .fp-section.first .flex-center .col:nth-of-type(2) img {
	width: 80%;
	height: auto;
	margin: 0 auto;
	display: block;
	margin-bottom: 30px;
}
@media (max-width: 960px) {
	.ledvance-22 .fp-section.first .flex-center .col:nth-of-type(2) img {
		margin-top: 30px;
	}
}
.ledvance-22 .fp-section.first .flex-center p,
.ledvance-22 .fp-section.first .flex-center ul li {
	font-size: 14px;
}
.ledvance-22 .fp-section.first .flex-center ul li:before {
	border-radius: 0;
	background: none;
	width: 0;
	height: 0;
	border-top: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-left: 3px solid #f60;
}
.ledvance-22.ledlescent .fp-section.first h2 {
	margin-bottom: 0;
}
.ledvance-22.ledlescent .fp-section.first p.center {
	text-align: center;
	padding: 0 10px;
	margin-bottom: 30px;
	font-size: 18px;
}
.ledvance-22.ledlescent .fp-section.first .flex-center {
	padding-right: 0;
}
.ledvance-22.ledlescent .fp-section.first .flex-center .col:nth-of-type(2) {
	-ms-flex-align: end;
	align-items: flex-end;
}
@media (max-width: 960px) {
	.ledvance-22.ledlescent .fp-section.first .flex-center .col:nth-of-type(2) {
		margin-top: 30px;
	}
}
.ledvance-22.ledlescent .fp-section.first .flex-center .col:nth-of-type(2) img {
	margin: 0 0 30px;
	width: 100%;
}
.ledvance-22.ledlescent .fp-section.first .flex-center .col:nth-of-type(2) ul {
	margin-right: 30px;
}
.ledvance-22.ledlescent .fp-section.first p,
.ledvance-22.ledlescent .fp-section.first ul li {
	font-size: 16px;
}
.eaton-cable-management-22 .fp-hero .col {
	width: 100%;
	min-height: 250px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	background-size: cover;
	background-position: 50%;
}
.eaton-cable-management-22 .fp-hero .col h1 {
	font-size: 42px !important;
	color: #fff !important;
	text-align: center;
	text-shadow: 0 0 10px #0072bb;
}
.eaton-cable-management-22 ul li:before {
	background-color: #000 !important;
}
.eaton-cable-management-22 ul {
	margin-top: 20px !important;
	display: block;
}
.eaton-cable-management-22 .section-01 {
	-ms-flex-direction: column;
	flex-direction: column;
}
.eaton-cable-management-22 .section-01.first {
	border-bottom: 2px solid #0072bb;
	padding-bottom: 50px;
}
.eaton-cable-management-22 .section-01 .top {
	width: 100%;
	padding: 10px 50px;
}
@media (max-width: 960px) {
	.eaton-cable-management-22 .section-01 .top {
		padding: 10px 30px;
	}
}
.eaton-cable-management-22 .section-01 .top h2 {
	color: #0072bb !important;
	text-transform: inherit !important;
}
.eaton-cable-management-22 .section-01 .top p {
	margin-bottom: 20px;
}
.eaton-cable-management-22 .section-01 .flex-center {
	background: #f2f2f2;
	padding: 0 50px 10px;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 960px) {
	.eaton-cable-management-22 .section-01 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0 30px 10px;
	}
}
.eaton-cable-management-22 .section-01 .flex-center .col:first-of-type {
	width: 50%;
	padding-right: 30px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 960px) {
	.eaton-cable-management-22 .section-01 .flex-center .col:first-of-type {
		width: 100%;
		margin: 30px 0;
	}
}
.eaton-cable-management-22 .section-01 .flex-center .col:first-of-type img {
	width: 100%;
	height: auto;
}
.eaton-cable-management-22 .section-01 .flex-center p.title {
	background: #0072bb;
	padding: 10px 30px;
	text-transform: uppercase;
	text-align: center;
	color: #fff !important;
	width: 200px;
}
.eaton-cable-management-22 .video-section .col {
	width: 80%;
	margin: 30px auto;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 960px) {
	.eaton-cable-management-22 .video-section .col {
		width: 100%;
	}
}
.eaton-cable-management-22 .fp-footer {
	background: #5b6770;
}
.eaton-cable-management-22 .fp-footer:after {
	border-left: 60px solid #5b6770;
}
.eaton-cable-management-22 .fp-footer .fp-btn {
	color: #fff;
	background: #5b6770;
	border: 2px solid #fff;
	text-transform: none;
}
.eaton-arkgard-22 .fp-hero,
.eaton-wire-mesh-22 .fp-hero {
	background: #0072bb;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .fp-hero,
	.eaton-wire-mesh-22 .fp-hero {
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}
}
.eaton-arkgard-22 .fp-hero .col:first-of-type,
.eaton-wire-mesh-22 .fp-hero .col:first-of-type {
	min-height: 400px;
	width: 35%;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .fp-hero .col:first-of-type,
	.eaton-wire-mesh-22 .fp-hero .col:first-of-type {
		width: 100%;
		min-height: 300px;
	}
}
.eaton-arkgard-22 .fp-hero .col:nth-of-type(2),
.eaton-wire-mesh-22 .fp-hero .col:nth-of-type(2) {
	width: 65%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: start;
	align-items: flex-start;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .fp-hero .col:nth-of-type(2),
	.eaton-wire-mesh-22 .fp-hero .col:nth-of-type(2) {
		width: 100%;
		padding-bottom: 30px;
	}
}
.eaton-arkgard-22 .fp-hero .col:nth-of-type(2) h1,
.eaton-wire-mesh-22 .fp-hero .col:nth-of-type(2) h1 {
	color: #fff !important;
	padding: 0 0 15px 30px;
	font-size: 36px !important;
}
.eaton-arkgard-22 .fp-hero .col:nth-of-type(2) p.callout,
.eaton-wire-mesh-22 .fp-hero .col:nth-of-type(2) p.callout {
	background: #000;
	font-size: 24px !important;
	padding: 10px 30px;
	margin-bottom: 10px !important;
	width: 100%;
	color: #ffba00 !important;
	text-transform: uppercase;
}
.eaton-arkgard-22 .fp-hero .col:nth-of-type(2) p,
.eaton-wire-mesh-22 .fp-hero .col:nth-of-type(2) p {
	padding: 0 30px;
	color: #fff !important;
	font-size: 16px !important;
}
.eaton-arkgard-22 .fp-hero .col:nth-of-type(2) p.title,
.eaton-wire-mesh-22 .fp-hero .col:nth-of-type(2) p.title {
	font-size: 22px !important;
	font-weight: 700;
	padding-bottom: 0;
}
.eaton-arkgard-22 .big-img,
.eaton-wire-mesh-22 .big-img {
	padding: 0 30px;
}
.eaton-arkgard-22 .big-img img,
.eaton-wire-mesh-22 .big-img img {
	width: 100%;
	height: auto;
}
.eaton-arkgard-22 .section-01,
.eaton-wire-mesh-22 .section-01 {
	padding: 30px;
}
.eaton-arkgard-22 .section-01 .flex-center,
.eaton-wire-mesh-22 .section-01 .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01 .flex-center,
	.eaton-wire-mesh-22 .section-01 .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.eaton-arkgard-22 .section-01 .col,
.eaton-wire-mesh-22 .section-01 .col {
	width: calc((100% - 60px) / 3);
	background: #cce3f1;
	padding: 20px 30px 5px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01 .col,
	.eaton-wire-mesh-22 .section-01 .col {
		width: 100%;
	}
	.eaton-arkgard-22 .section-01 .col:not(:last-of-type),
	.eaton-wire-mesh-22 .section-01 .col:not(:last-of-type) {
		margin-bottom: 30px;
	}
}
.eaton-arkgard-22 .section-01 .col p,
.eaton-wire-mesh-22 .section-01 .col p {
	font-size: 16px;
}
.eaton-arkgard-22 .section-01 .col p.title,
.eaton-wire-mesh-22 .section-01 .col p.title {
	font-size: 22px;
	font-weight: 700;
}
.eaton-arkgard-22 .section-01 .col img,
.eaton-wire-mesh-22 .section-01 .col img {
	width: 100%;
	height: auto;
	-ms-flex-item-align: end;
	align-self: flex-end;
	justify-self: flex-end;
}
.eaton-arkgard-22 .section-01.diagram,
.eaton-wire-mesh-22 .section-01.diagram {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-pack: justify;
	justify-content: space-between;
	position: relative;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01.diagram,
	.eaton-wire-mesh-22 .section-01.diagram {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.eaton-arkgard-22 .section-01.diagram .product,
.eaton-wire-mesh-22 .section-01.diagram .product {
	position: absolute;
	top: 5%;
	left: 20%;
	width: 40%;
	z-index: 0;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01.diagram .product,
	.eaton-wire-mesh-22 .section-01.diagram .product {
		position: relative;
		top: auto;
		left: auto;
		width: 100%;
		display: block;
		margin: 0 auto 30px;
		max-width: 400px;
	}
}
.eaton-arkgard-22 .section-01.diagram .left-col,
.eaton-wire-mesh-22 .section-01.diagram .left-col {
	width: 335px;
	-ms-flex: none;
	flex: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: justify;
	justify-content: space-between;
	position: relative;
	z-index: 6;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01.diagram .left-col,
	.eaton-wire-mesh-22 .section-01.diagram .left-col {
		width: 100%;
	}
}
.eaton-arkgard-22 .section-01.diagram .left-col .col,
.eaton-wire-mesh-22 .section-01.diagram .left-col .col {
	width: 100%;
}
.eaton-arkgard-22 .section-01.diagram .left-col .col:first-of-type,
.eaton-wire-mesh-22 .section-01.diagram .left-col .col:first-of-type {
	width: 80%;
}
.eaton-arkgard-22 .section-01.diagram .left-col .col:first-of-type div,
.eaton-wire-mesh-22 .section-01.diagram .left-col .col:first-of-type div {
	width: 100%;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01.diagram .left-col .col:first-of-type,
	.eaton-wire-mesh-22 .section-01.diagram .left-col .col:first-of-type {
		width: 100%;
	}
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01.diagram .left-col .col,
	.eaton-wire-mesh-22 .section-01.diagram .left-col .col {
		margin-bottom: 30px;
	}
}
.eaton-arkgard-22 .section-01.diagram .right-col,
.eaton-wire-mesh-22 .section-01.diagram .right-col {
	width: 375px;
	-ms-flex: none;
	flex: none;
	position: relative;
	z-index: 4;
}
@media (max-width: 960px) {
	.eaton-arkgard-22 .section-01.diagram .right-col,
	.eaton-wire-mesh-22 .section-01.diagram .right-col {
		width: 100%;
	}
}
.eaton-arkgard-22 .section-01.diagram .right-col .col,
.eaton-wire-mesh-22 .section-01.diagram .right-col .col {
	width: 100%;
	position: relative;
}
.eaton-arkgard-22 .section-01.diagram .right-col .col:not(:last-of-type),
.eaton-wire-mesh-22 .section-01.diagram .right-col .col:not(:last-of-type) {
	margin-bottom: 30px;
}
.eaton-arkgard-22 .section-01.diagram .right-col .col:before,
.eaton-wire-mesh-22 .section-01.diagram .right-col .col:before {
	width: 100px;
	height: 1px;
	background: #000;
	content: "";
	display: block;
	position: absolute;
	top: 50px;
	left: -95px;
	z-index: 0;
}
@media (max-width: 992px) {
	.eaton-arkgard-22 .section-01.diagram .right-col .col:before,
	.eaton-wire-mesh-22 .section-01.diagram .right-col .col:before {
		display: none;
	}
}
.eaton-arkgard-22 .section-01.diagram .right-col .col:first-of-type:before,
.eaton-wire-mesh-22 .section-01.diagram .right-col .col:first-of-type:before {
	transform: rotate(-25deg);
	top: 50px;
	left: -95px;
}
.eaton-arkgard-22 .section-01.diagram .right-col .col:nth-of-type(2):before,
.eaton-wire-mesh-22 .section-01.diagram .right-col .col:nth-of-type(2):before {
	transform: rotate(0);
	top: 50%;
	left: -95px;
}
.eaton-arkgard-22 .section-01.diagram .right-col .col:nth-of-type(3):before,
.eaton-wire-mesh-22 .section-01.diagram .right-col .col:nth-of-type(3):before {
	width: 200px;
	transform: rotate(30deg);
	top: 5px;
	left: -190px;
}
.eaton-arkgard-22 .section-01.diagram .col,
.eaton-wire-mesh-22 .section-01.diagram .col {
	background: #0072bb;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border: 1px solid #000;
	padding: 0;
}
.eaton-arkgard-22 .section-01.diagram .col img,
.eaton-wire-mesh-22 .section-01.diagram .col img {
	width: 30%;
	-ms-flex-item-align: center;
	align-self: center;
}
.eaton-arkgard-22 .section-01.diagram .col div,
.eaton-wire-mesh-22 .section-01.diagram .col div {
	width: 70%;
	padding: 15px;
}
.eaton-arkgard-22 .section-01.diagram .col p,
.eaton-wire-mesh-22 .section-01.diagram .col p {
	color: #fff !important;
	margin: 0;
	font-size: 12px;
}
.eaton-arkgard-22 .section-01.diagram .col p.title,
.eaton-wire-mesh-22 .section-01.diagram .col p.title {
	font-size: 18px;
}
.eaton-arkgard-22 .fp-footer,
.eaton-wire-mesh-22 .fp-footer {
	background: #5b6770;
}
.eaton-arkgard-22 .fp-footer:after,
.eaton-wire-mesh-22 .fp-footer:after {
	border-left: 60px solid #5b6770;
}
.eaton-arkgard-22 .fp-footer .fp-btn,
.eaton-wire-mesh-22 .fp-footer .fp-btn {
	color: #fff;
	background: #5b6770;
	border: 2px solid #fff;
	text-transform: none;
}
.fall-specials.featured-products .fp-header h1 {
	width: 500px;
}
@media (max-width: 960px) {
	.fall-specials.featured-products .fp-header h1 {
		width: calc(100% - 100px);
		font-size: 24px !important;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
	}
}
.fall-specials.featured-products .fp-angle {
	padding: 10px 50px 5px;
	background: #f58220;
}
@media (max-width: 960px) {
	.fall-specials.featured-products .fp-angle {
		padding: 10px 30px 5px;
	}
}
.fall-specials.featured-products .fp-angle p {
	color: #fff !important;
}
.fall-specials.featured-products .fp-angle p span {
	color: #1d4486;
}
.fall-specials.featured-products .fp-angle p.dsc {
	font-size: 1.25em;
}
.fall-specials.featured-products .fp-angle-triangle {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 55px;
	display: block;
	margin-bottom: -55px;
	top: 0;
}
@media (max-width: 960px) {
	.fall-specials.featured-products .fp-angle-triangle {
		margin-top: -20px;
	}
}
.fall-specials.featured-products .fp-angle-triangle:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border-left: 510px solid transparent;
	border-right: 510px solid transparent;
	border-top: 50px solid #f58220;
}
@media (max-width: 1050px) {
	.fall-specials.featured-products .fp-angle-triangle:after {
		left: calc((100vw - 1020px) / 2);
	}
}
.fall-specials.featured-products .fp-two-col {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
}
@media (max-width: 900px) {
	.fall-specials.featured-products .fp-two-col {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.fall-specials.featured-products .fp-two-col .fp-col {
	width: 50%;
	padding: 70px 50px 50px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media (max-width: 900px) {
	.fall-specials.featured-products .fp-two-col .fp-col {
		width: 100%;
		padding: 50px 30px;
	}
}
.fall-specials.featured-products .fp-two-col .fp-col:first-of-type {
	background-color: #f2f2f2;
}
@media (max-width: 900px) {
	.fall-specials.featured-products .fp-two-col .fp-col:first-of-type {
		padding-top: 75px;
	}
}
.fall-specials.featured-products .fp-two-col .fp-col:nth-of-type(2) {
	background-color: #fff;
}
.fall-specials.featured-products .fp-two-col .fp-col p.title {
	position: relative;
	width: 100%;
	color: #1d4486 !important;
	text-transform: uppercase;
	font-size: 24px !important;
	margin-top: 20px;
}
.fall-specials.featured-products .fp-two-col .fp-col p.title:before {
	content: "";
	width: 7px;
	height: 7px;
	border-radius: 50%;
	position: absolute;
	left: -25px;
	top: 12.5px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.fall-specials.featured-products .fp-two-col .fp-col p.title:before {
		display: none;
	}
}
.fall-specials.featured-products .fp-two-col .fp-col p.title:after {
	content: "";
	width: 30px;
	height: 1px;
	border-radius: 50%;
	position: absolute;
	left: -50px;
	top: 15px;
	background-color: #f58220;
}
@media (max-width: 960px) {
	.fall-specials.featured-products .fp-two-col .fp-col p.title:after {
		display: none;
	}
}
.fall-specials.featured-products .fp-two-col .fp-col p {
	font-size: 16px !important;
}
.fall-specials.featured-products .fp-two-col .fp-col img {
	width: 100%;
	height: auto;
}
.fall-specials.featured-products .fp-two-col .fp-col img.logo {
	max-width: 300px;
	height: auto;
}
.fall-specials.featured-products .fp-two-col .fp-col img.prod {
	margin: 20px 0;
}
.fall-specials.featured-products .fp-two-col .fp-col .fp-btn {
	background-color: #1d4486;
	color: #fff;
	text-transform: uppercase;
	font-size: 18px;
	padding: 10px 20px;
}
.cooper-metalux-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.cooper-metalux-22 .fp-footer {
	background: #3d3935;
}
.cooper-metalux-22 .fp-footer:after {
	border-left: 60px solid #3d3935;
}
.cooper-metalux-22 .fp-footer p {
	font-size: 16px !important;
	font-weight: 700;
}
.cooper-metalux-22 .fp-footer .fp-btn {
	color: #fff;
	background: #de5636;
	border: 2px solid #de5636;
	border-radius: 15px;
	text-transform: none;
}
.cooper-metalux-22 .fp-hero {
	margin-bottom: 0;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-hero {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.cooper-metalux-22 .fp-hero .col {
	min-height: 300px;
}
.cooper-metalux-22 .fp-hero .col:first-of-type {
	max-width: 500px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 50px 75px;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-hero .col:first-of-type {
		width: 100%;
		padding: 50px 30px;
		min-height: inherit;
	}
}
.cooper-metalux-22 .fp-hero .col:first-of-type h1 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
	color: #000 !important;
	font-size: 36px !important;
	font-weight: 100 !important;
	color: #fff !important;
}
.cooper-metalux-22 .fp-hero .col:first-of-type p {
	color: #fff !important;
	font-size: 14px !important;
}
.cooper-metalux-22 .fp-hero .col:first-of-type a {
	background-color: #6e6e6e;
	padding: 12px 10px;
	color: #fff;
	font-size: 18px;
}
.cooper-metalux-22 .fp-hero .col:nth-of-type(2) {
	width: 60%;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-hero .col:nth-of-type(2) {
		width: 100%;
	}
}
.cooper-metalux-22 .fp-section.first {
	padding: 30px 50px;
	background: #3d3935;
	margin-bottom: 0;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-section.first {
		padding: 30px;
	}
}
.cooper-metalux-22 .fp-section.first h2,
.cooper-metalux-22 .fp-section.first p {
	color: #fff !important;
	text-align: center;
}
.cooper-metalux-22 .fp-section.first h2 {
	text-transform: inherit !important;
	font-weight: 600 !important;
	font-size: 28px !important;
	position: relative;
}
.cooper-metalux-22 .fp-section.first h2:before {
	content: "";
	width: 30px;
	height: 3px;
	position: absolute;
	top: -15px;
	left: 0;
	background-color: #fff;
}
.cooper-metalux-22 .fp-section.solution {
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 0 0 30px;
}
.cooper-metalux-22 .fp-section.solution h2 {
	background: #767472;
	padding: 10px 50px;
	margin: 0 !important;
	font-size: 20px !important;
	color: #fff !important;
	font-weight: 100 !important;
	text-align: center;
	text-transform: inherit !important;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
.cooper-metalux-22 .fp-section.solution .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	margin: 0 0 10px;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-section.solution .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:first-of-type {
	width: 50%;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-section.solution .flex-center .col:first-of-type {
		width: 100%;
	}
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:first-of-type img {
	width: 100%;
	height: auto;
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) {
	width: 50%;
	padding: 30px;
	background: #f2f2f2;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) {
		width: 100%;
	}
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) p {
	font-size: 16px;
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) p:first-of-type {
	margin-bottom: 20px;
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) p.title {
	font-size: 20px;
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) p.title:after {
	content: "";
	width: 50px;
	height: 1px;
	margin: 10px 0 0;
	background-color: #b73244;
	display: block;
}
.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) img {
	width: 100%;
	height: auto;
	margin-left: -10px;
}
@media (max-width: 960px) {
	.cooper-metalux-22 .fp-section.solution .flex-center .col:nth-of-type(2) img {
		margin-left: 0;
	}
}
.cooper-halo-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.cooper-halo-22 .fp-footer {
	background: #3d3935;
}
.cooper-halo-22 .fp-footer:after {
	border-left: 60px solid #3d3935;
}
.cooper-halo-22 .fp-footer p {
	font-size: 16px !important;
	font-weight: 700;
}
.cooper-halo-22 .fp-footer .fp-btn {
	color: #fff;
	background: #de5636;
	border: 2px solid #de5636;
	border-radius: 15px;
	text-transform: none;
}
.cooper-halo-22 .fp-hero {
	margin-bottom: 0;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.cooper-halo-22 .fp-hero .col:first-of-type {
	width: 30%;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero .col:first-of-type {
		width: 100%;
	}
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) {
	width: 70%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero .col:nth-of-type(2) {
		width: 100%;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) h1 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
	color: #000 !important;
	font-size: 36px !important;
	font-weight: 100 !important;
	color: #fff !important;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) p {
	color: #fff !important;
	font-size: 14px !important;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) a {
	background-color: #6e6e6e;
	padding: 12px 10px;
	color: #fff;
	font-size: 18px;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center {
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: center;
		align-items: center;
	}
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:first-of-type {
	background-color: #f26a36;
	padding: 30px;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center .col:first-of-type {
	width: 400px;
	max-width: 400px;
	border-left: 3px solid #fff;
	padding-left: 20px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-direction: column;
	flex-direction: column;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center .col:first-of-type {
		width: 100%;
		max-width: 100%;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center .col:first-of-type img {
	width: 250px;
	height: auto;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center .col:first-of-type p {
	font-size: 20px !important;
	margin: 0 !important;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center .col:nth-of-type(2) {
	max-width: calc(100% - 400px);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center .col:nth-of-type(2) {
		width: 100%;
	}
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center .col:nth-of-type(2) img {
	width: 100px;
	height: auto;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:nth-of-type(2) .col:first-of-type {
	width: 50%;
	padding: 30px;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:nth-of-type(2) .col:first-of-type {
		width: 100%;
	}
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:nth-of-type(2) .col:first-of-type p {
	color: #000 !important;
	font-size: 16px !important;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:nth-of-type(2) .col:first-of-type img {
	width: 100%;
	height: auto;
	margin-top: 30px;
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:nth-of-type(2) .col:nth-of-type(2) {
	width: 50%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-top: 30px;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:nth-of-type(2) .col:nth-of-type(2) {
		width: 100%;
	}
}
.cooper-halo-22 .fp-hero .col:nth-of-type(2) .flex-center:nth-of-type(2) .col:nth-of-type(2) img {
	width: 100%;
	height: auto;
}
.cooper-halo-22 .fp-section.solution {
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 0 0 30px;
}
.cooper-halo-22 .fp-section.solution h2 {
	background: #000;
	padding: 10px 50px;
	margin: 0 !important;
	font-size: 20px !important;
	color: #fff !important;
	font-weight: 100 !important;
	text-transform: inherit !important;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
.cooper-halo-22 .fp-section.solution .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 30px 0;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-section.solution .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.cooper-halo-22 .fp-section.solution .flex-center .col {
	width: 50%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 15px;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-section.solution .flex-center .col {
		width: 100%;
	}
}
.cooper-halo-22 .fp-section.solution .flex-center .col p {
	font-size: 16px !important;
	margin: 0 !important;
	color: #000 !important;
}
.cooper-halo-22 .fp-section.solution .flex-center .col p.title {
	font-weight: 700 !important;
	font-size: 20px !important;
	margin-bottom: 20px !important;
}
.cooper-halo-22 .fp-section.solution .flex-center .col:first-of-type img {
	width: 100%;
	height: auto;
}
.cooper-halo-22 .fp-section.solution .flex-center .col:nth-of-type(2) {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}
.cooper-halo-22 .fp-section.solution .flex-center .col .inner {
	width: 50%;
	max-width: 200px;
	padding-left: 20px;
}
.cooper-halo-22 .fp-section.solution .flex-center .item {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 15px;
}
@media (max-width: 960px) {
	.cooper-halo-22 .fp-section.solution .flex-center .item {
		width: 100%;
	}
}
.cooper-halo-22 .fp-section.solution .flex-center .item p {
	font-size: 16px !important;
	margin: 0 !important;
}
.cooper-halo-22 .fp-section.solution .flex-center .item img {
	width: 30%;
	height: auto;
}
.cooper-halo-22 .fp-section.solution .flex-center .item .inner {
	width: 70%;
	max-width: 200px;
	padding-left: 20px;
}
.cooper-halo-22 .fp-section.solution .btn {
	background: #000;
	color: #fff;
	padding: 10px 20px;
	display: table;
	margin: 0 auto;
}
.atlas-if-22 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
}
.atlas-if-22 .fp-footer {
	background: #43454b;
}
.atlas-if-22 .fp-footer:after {
	border-left: 60px solid #43454b;
}
.atlas-if-22 .fp-footer p {
	font-size: 16px !important;
	font-weight: 700;
}
.atlas-if-22 .fp-footer .fp-btn {
	color: #fff;
	background: #002e5d;
	border: 2px solid #002e5d;
	border-radius: 5px;
	text-transform: none;
}
.atlas-if-22 .fp-hero {
	margin-bottom: 0;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.atlas-if-22 .fp-hero {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.atlas-if-22 .fp-hero .col:first-of-type {
	width: 50%;
	background-size: cover;
	background-position: 50%;
}
@media (max-width: 960px) {
	.atlas-if-22 .fp-hero .col:first-of-type {
		width: 100%;
		height: 400px;
	}
}
.atlas-if-22 .fp-hero .col:nth-of-type(2) {
	width: 50%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 30px 50px 30px 30px;
	background-color: #002e5d;
}
.atlas-if-22 .fp-hero .col:nth-of-type(2) img {
	width: 250px;
	height: auto;
	margin-bottom: 15px;
}
@media (max-width: 960px) {
	.atlas-if-22 .fp-hero .col:nth-of-type(2) {
		width: 100%;
		padding: 30px;
	}
}
.atlas-if-22 .fp-hero .col:nth-of-type(2) h1 {
	font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
	color: #000 !important;
	font-size: 36px !important;
	font-weight: 100 !important;
	color: #fff !important;
}
.atlas-if-22 .fp-hero .col:nth-of-type(2) p {
	color: #fff !important;
	font-size: 16px !important;
}
.atlas-if-22 .fp-hero .col:nth-of-type(2) p a {
	font-weight: 700 !important;
	text-decoration: underline;
	color: #fff !important;
}
.atlas-if-22 .fp-hero .col:nth-of-type(2) p strong {
	font-size: 18px !important;
}
.atlas-if-22 .fp-section.solution {
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 30px auto;
	width: 80%;
}
.atlas-if-22 .fp-section.solution h2 {
	border-bottom: 2px solid #000;
	margin: 0 !important;
	font-size: 28px !important;
	color: #cf152d !important;
	font-weight: 700 !important;
	text-align: center;
	text-transform: uppercase !important;
}
.atlas-if-22 .fp-section.solution .flex-center {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
@media (max-width: 960px) {
	.atlas-if-22 .fp-section.solution .flex-center {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
.atlas-if-22 .fp-section.solution .flex-center .col {
	width: 33%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 15px;
}
@media (max-width: 960px) {
	.atlas-if-22 .fp-section.solution .flex-center .col {
		width: 100%;
	}
}
.atlas-if-22 .fp-section.solution .flex-center .col p {
	font-size: 14px !important;
	margin: 0 !important;
}
.atlas-if-22 .fp-section.solution .flex-center .col p.title {
	font-size: 18px !important;
	font-weight: 700 !important;
	margin-bottom: 10px !important;
}
.atlas-if-22 .fp-section.solution .flex-center .col img {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}
/*# sourceMappingURL=custom-2021.css.map */
