.page-template-locations-2024-template {
	.featured-popup {
		display: none;
	}
}

.map-section {
	background: color(white);

	.column-layout {
		display: flex;
		align-items: center;
		width: 100%;
		height: calc(100vh - 204px);

		@media (max-width: bp(nav)) {
			height: auto;
		}

		.col {
			&:nth-of-type(1) {
				width: 400px;
				height: 100%;
				display: flex;
				flex-direction: column;

				@media (max-width: bp(nav)) {
					width: 100%;
				}
			}

			&:nth-of-type(2) {
				width: calc(100% - 400px);
				height: 100%;

				@media (max-width: bp(nav)) {
					display: none;
				}
			}
		}
	}

	.top {
		padding: 20px;
		font-size: 12px;
		height: auto;
		flex: none;

		@media (max-width: bp(nav)) {
			top: 40px;
			z-index: 9;
			position: sticky;
			background: color(white);
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
		}

		.title3 {
			font-size: 24px;
			color: color(accent);
			font-weight: 600;
		}

		p {
			color: color(accent);
		}
	}

	[data-map] {
		width: 100%;
		height: 100%;
	}

	[data-categories] {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;

		.category {
			cursor: pointer;

			margin: 0 0 5px 0;
			width: 50%;
			display: flex;
			align-items: flex-start;

			.cb {
				width: 10px;
				height: 10px;
				margin-right: 10px;
				margin-top: 2px;
				display: block;
				border: 1px solid color(accent);
				background-color: color(white);
				transition: background-color 0.5s, border-color 0.5s;
			}

			p {
				margin: 0;
				line-height: 1.3;
				color: black;
			}

			&.active {
				p {
					font-weight: bold;
				}

				.cb {
					background-color: color(accent, orange);
					border-color: color(accent, orange);
				}
			}
		}
	}

	[data-pois] {
		width: 100%;
		height: auto;
		overflow: scroll;
		position: relative;
		scroll-behavior: smooth;

		.poi {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	.info-window {
		width: 360px;
		//height: 100px;
		padding: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.header {
			background: color(accent);
			padding: 10px 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			p,
			a {
				color: color(white);
				margin: 0;
			}

			p {
				font-size: 16px;
			}

			a {
				text-decoration: underline;
				margin-left: 10px;
				font-size: 12px;
			}
		}

		.inner {
			padding: 10px 20px;
			width: 100%;

			p {
				font-size: 12px;

				&.branches {
					font-style: italic;
					color: color(accent, orange);
				}

				&.address:not(.map) {
					display: none;
				}

				&.address {
					margin: 0;
				}

				&.hours {
					display: flex;
					align-items: center;

					span {
						&:not(:last-child) {
							border-right: 1px solid black;
							padding-right: 5px;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

	.gm-style {
		.gm-style-iw-d {
			overflow: auto !important;
		}

		.gm-style-iw-c {
			padding: 0;
			border-radius: 0;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
		}

		[aria-label="Close"] {
			width: 20px !important;
			height: 20px !important;
			top: 0 !important;
			right: 0 !important;
			display: flex !important;
			align-items: center;
			justify-content: center;
			border: 1px solid black !important;
			display: none !important;

			span {
				margin: 0 !important;
			}
		}
	}

	[data-pois] {
		padding: 0 20px 50px;

		.info-window {
			border: 1px solid color(accent);
			width: 100%;
			margin-bottom: 15px;
			cursor: pointer;

			.hours {
				display: flex;
				flex-direction: column;
				align-items: flex-start !important;

				span {
					margin: 0;
					padding: 0;
					border: 0 !important;
				}
			}

			.address {
				display: block !important;
			}

			.address.map {
				display: none !important;
			}
		}
	}
}
