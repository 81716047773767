.page-template-contact-template,
.page-template-registration-template {
	.container-wrapper.clearfix {
		background: #fff !important;
	}

	.nf-form-cont {
		font-size: 14px;
		min-height: 20px;
		padding: 19px;
		margin-bottom: 20px;
		background-color: #f5f5f5;
		border: 1px solid #e3e3e3;
		border-radius: 4px;

		nf-fields-wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		nf-field {
			width: 100%;

			&:nth-of-type(1),
			&:nth-of-type(2) {
				width: calc((100% - 30px) / 2);
				display: flex;

				@media (max-width: 1024px) {
					width: 100%;
				}
			}

			.nf-field-container {
				width: 100%;
			}
		}
	}
}

#mailModal,
.page-template-contact-template,
.page-template-registration-template {
	.nf-form-content {
		input,
		label,
		select,
		textarea {
			font-size: 14px;
		}

		input {
			background: #fff;
			display: block;
			width: 100%;
			height: 34px;
			padding: 6px 12px;
			font-size: 14px;
			line-height: 1.42857143;
			color: #555;
			background-color: #fff !important;
			background-image: none;
			border: 1px solid #ccc;
			border-radius: 4px;
			-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
			-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
			transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
		}

		select,
		textarea {
			background: #fff;
		}

		.submit-container input {
			color: #555;
		}

		.nf-before-form-content {
			display: none;
		}
	}
}

.page-template-registration-template {
	nf-field {
		&:nth-of-type(1),
		&:nth-of-type(2),
		&:nth-of-type(11),
		&:nth-of-type(12),
		&:nth-of-type(13),
		&:nth-of-type(14),
		&:nth-of-type(16),
		&:nth-of-type(17) {
			width: calc((100% - 30px) / 2);
			display: flex;

			@media (max-width: 1024px) {
				width: 100%;
			}
		}

		&:nth-of-type(8),
		&:nth-of-type(9),
		&:nth-of-type(10) {
			width: calc((100% - 60px) / 3);
			display: flex;

			@media (max-width: 1024px) {
				width: 100%;
			}
		}
	}
}

#mailModal {
	padding: 20px;

	.nf-field-container {
		margin-bottom: 5px;
	}
}

/****************************************
Responsive video frame
****************************************/
.responsive-video {
	width: 100%;

	.video-size-frame {
		height: 0;
		padding-bottom: 56.3%;
		position: relative;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

#body .responsive-video {
	max-width: 80%;
	height: auto;
	margin: 30px auto;
	display: block;

	@media (max-width: 768px) {
		max-width: 100%;
	}
}

#body {
	img {
		max-width: 100%;
		height: auto;
	}
}

.socials {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	a {
		flex: none;

		&:not(:last-of-type) {
			margin-right: 10px;
		}
	}
}

.new-homepage img {
	max-width: 100%;
	height: auto;
}

#emergency p {
	margin: 0;
}
